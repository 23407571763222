<i18n>
{
    "ru": {
        "at": "в"
    }
}
</i18n>
<template>
    <div
        class="perfumes-slider">

        <PageSlider
            class="perfumes-slider__swiper"
            :sections="sections">

            <template #item="{ section }">
                <div class="perfumes-slider__slide">
                    <div
                        v-for="item in section"
                        :key="item.code">

                        <BlockOffer
                            class="perfumes-slider__item"
                            :name="get(item, 'name')"
                            :url="get(item, 'htmlUrl')"
                            :picture="get(item, 'picture.url')"
                            :alt="get(item, 'picture.alt')"
                            with-brand
                            :brand-name="get(item, 'brand.name', 'CHANEL')"
                            direction-column
                            :with-comment="false"
                            :with-rating="withRating"
                            :rating="get(item, 'ratings.value', 0)"
                            :caption="get(item, 'concentration.name', 0)"/>

                    </div>
                </div>
            </template>
        </PageSlider>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import chunk from 'lodash/chunk'
import get from 'lodash/get'

import PageSlider from '@/components/layout/blocks/PageSlider'
import BlockOffer from '@/components/catalog/BlockOffer'

export default {
    name: 'PerfumesSlider',
    components: {
        PageSlider,
        BlockOffer
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        withRating: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        sections() {
            return chunk(this.items, 4)
        }
    },
    methods: {
        get,
        chunk,
        getDate(value) {
            const date = new Date(value)

            return dayjs(date).format('DD.MM.YYYY ' + this.$t('at') + ' HH:mm')
        },
    }


}
</script>

<style lang="less">
.perfumes-slider {

    // .page-slider{
    //     &__dots{
    //         @{mobile} &{
    //             padding-top: rem(19);
    //             padding-bottom: rem(14);
    //         }
    //     }
    //     &__button{
    //         @{mobile} &{
    //             bottom: rem(13);
    //         }
    //     }
    // }

    &__slide {
        display: flex;
        flex-wrap: wrap;

        &>div{
            width: 50%;
        }

        margin-bottom: 0;
        padding-top: rem(4);
        padding-bottom: rem(8);
        padding-left: rem(11);
        padding-right: rem(11);
        position: relative;
    }

    &__item {
        --height-item-block: rem(224);

        padding-top: 0;
        padding-bottom: 0;

        img[src] {
            @{mobile} &,
            @{desktop} & {
                opacity: 1;
            }
        }
    }
}

</style>
