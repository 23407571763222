<i18n>
{
    "ru": {
        "show_replies_start": "Показать",
        "replies_one": "ответ",
        "replies_few": "ответа",
        "replies_many": "ответов",
        "hide_replies_start": "Скрыть",
        "guest": "Гость",
        "reply": "Ответить",
        "follow": "Перейти",
        "show_user_comments": "Все отзывы пользователя",
        "hide_user_comments": "Скрыть отзывы пользователя",
        "at": "в",
        "he_left_a_review": "оставил отзыв",
        "she_left_a_review": "оставила отзыв",
        "is_brand_review": "о бренде",
        "go_to_post": "Перейти",
        "he_reply": "Ответил",
        "she_reply": "Ответила",
        "for_comment": "на комментарий",
        "for_user": "пользователя"
    }
}
</i18n>

<template>
    <section
        class="feed-post"
        :id="blockId">

        <BlockFeedPostHeader
            :showPostAuthorName="showPostAuthorName"
            :no-gender="noGender"
            :showActionsButton="false"
            class="feed-post__header"
            :name="fullName"
            :gender="gender"
            :photo="photo"
            :user-deleted="noPhoto"
            :post-type="getPostType"
            :comment-type="commentType || reviewType"
            :author-post="authorPost || ''"
            :author-profile="userProfile"
            :parent="postData.parent"
            :created-by="postData.createdBy"
            :rating="rating"
            :post-id="postData.id"
            :current-user="currentUser"
            :post-date="postData.createdAt" />

        <template v-if="showLess">
            <!--eslint-disable-next-line-->
            <div v-html="$options.filters.textNoBr(text)" class="feed-post__content feed-post__content_less"></div>
            <div class="feed-post__date">{{ postDate }}</div>
        </template>

        <template v-else>
            <div class="feed-post__content">
                <!--eslint-disable-next-line-->
                <div v-html="commentObjectName"
                    v-if="!get(postData, 'parent')"
                    class="feed-post__rating">
                </div>

                <!--eslint-disable-next-line-->
                <div v-if="!authorPost" v-html="title"
                    class="feed-post__title">
                </div>

                <ShowMore
                    hideLessButton
                    ref="showMore"
                    :expanded="expanded"
                    :max-rows="maxRows"
                    :collapse-from="scrollToBlock"
                    :collapse-to="scrollToBlock">
                    <!--eslint-disable-next-line-->
                    <div v-html="text"></div>
                </ShowMore>
            </div>

            <div
                class="block-review__pictures"
                v-if="pictures.length">
                <picture
                    v-for="(picture, index) in pictures"
                    :key="picture.url"
                    @click="showGallery(index)">

                    <img
                        v-lazy-load :data-src="picture.url"
                        :alt="picture.alt || ''" />

                </picture>
            </div>

            <div class="feed-post__buttons">
                <RateButtons
                    class="feed-post__rate-buttons"
                    :comment-id="get(postData, 'id')"
                    :likes="likes"
                    :page="reviewType"
                    :code="get(reviewObject, 'code')"
                    :dislikes="dislikes" />

                <html-link
                    class="feed-post__go-to"
                    v-if="canAnswer && generatePostLink"
                    :url="generatePostLink + '#comment_' + get(postData, 'id', '')">{{ $t('go_to_post' )}}</html-link>
            </div>

            <TransitionExpand>
                <div class="block-review__replies" v-show="isShowReplies">

                    <BlockReview
                        class="block-review__reply"
                        :showDate="false"
                        :showCaptionReplyMainComment="true"
                        :idPrefix="idPrefix"
                        v-for="reply in replies"
                        :key="reply.id"
                        :inline-replies="true"
                        :scroll-to-new-reply="false"
                        :page="reviewType"
                        :caption-reply="computedCaptionReply(reply.gender)"
                        :code="get(reviewObject, 'code')"
                        :comment="reply"
                        :can-answer="false"
                        :top-parent-user="get(postData, 'createdBy', null)" />
                </div>
            </TransitionExpand>

            <footer class="feed-post__footer"
                v-if="countReplies">

                <transition
                    mode="out-in"
                    name="pop">

                    <button class="feed-post__button-show-replies"
                        v-if="countReplies"
                        :key="buttonShowText"
                        @click.prevent="triggerShowReplies">
                        {{ buttonShowText }}
                    </button>

                </transition>
            </footer>

        </template>
    </section>
</template>

<script>
// import { mapGetters } from 'vuex'
import get from 'lodash/get'
import orderBy from "lodash/orderBy";
import throttle from "lodash/throttle";
import RateButtons from '@/components/ui/RateButtons'
import BlockReview from '@/components/layout/blocks/BlockReview'
import BlockFeedPostHeader from '@/components/feed/blocks/BlockFeedPostHeader'

export default {
    name: 'BlockFeedUserComment',
    components: {
        RateButtons,
        BlockFeedPostHeader,
        BlockReview
    },
    props: {
        noGender: {
            type: Boolean,
            default: false
        },
        showLess: {
            type: Boolean,
            default: false
        },
        postType: {
            type: String,
            default: null
        },
        expanded: {
            type: Boolean,
            default: false
        },
        maxRows: {
            type: Number,
            default: 20
        },
        canAnswer: {
            type: Boolean,
            default: true
        },
        idPrefix: {
            type: String,
            default: 'comment'
        },
        postData: {
            type: Object,
            default: () => {}
        },
        noPhoto: {
            type: Boolean,
            default: false
        },
        typeOnlyComments: {
            type: Boolean,
            default: false
        },
        currentUser: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        rowsCount: 0,
        isShowReplies: false,
        replies: []
    }),
    computed: {
        showPostAuthorName() {
            if(get(this.postData, 'parent.createdBy.fullName')) return true

            return get(this.postData, 'createdBy.fullName') && this.commentType === 'post'
        },
        replyToId() {
            return get(this.postData, 'id');
        },
        isShowed() {
            return this.postData.text && this.postData.stars > 0
        },
        reviewType() {
            if(get(this.postData, 'perfume')) {
                return 'perfume'
            }

            if(get(this.postData, 'brand')) {
                return 'brand'
            }

            if(get(this.postData, 'shop')) {
                return 'shop'
            }

            if(get(this.postData, 'article')) {
                return 'article'
            }

            if(get(this.postData, 'news')) {
                return 'news'
            }

            return 'post'
        },
        reviewObject() {
            if(get(this.postData, 'perfume')) {
                return get(this.postData, 'perfume')
            }

            if(get(this.postData, 'brand')) {
                return get(this.postData, 'brand')
            }

            if(get(this.postData, 'shop')) {
                return get(this.postData, 'shop')
            }

            if(get(this.postData, 'article')) {
                return get(this.postData, 'article')
            }

            if(get(this.postData, 'news')) {
                return get(this.postData, 'news')
            }

            return get(this.postData, 'post')
        },
        commentObjectName() {
            if(get(this.postData, 'perfume')) {
                return get(this.postData, 'perfume.name')
            }

            if(get(this.postData, 'brand')) {
                return get(this.postData, 'brand.name')
            }

            if(get(this.postData, 'shop')) {
                return get(this.postData, 'shop.name')
            }

            if(get(this.postData, 'article')) {
                return get(this.postData, 'article.name')
            }

            if(get(this.postData, 'news')) {
                return get(this.postData, 'news.name')
            }

            return get(this.postData, 'perfume.name')
        },
        getPostType() {
            if(this.commentType){
                if(this.typeOnlyComments) {
                    // TODO: нужен рефакторинг этого компонента
                    return 'user_comment'
                }

                return get(this.postData, 'post') ? 'user_post' : 'user_comment'
            }else{
                return null
            }
        },
        commentType() {
            if(get(this.postData, 'perfume')) {
                return 'perfume'
            }

            if(get(this.postData, 'brand')) {
                return 'brand'
            }

            if(get(this.postData, 'shop')) {
                return 'shop'
            }

            if(get(this.postData, 'article')) {
                return 'article'
            }

            if(get(this.postData, 'news')) {
                return 'new'
            }

            if(get(this.postData, 'post')) {
                return 'post'
            }

            return null
        },
        generatePostLink() {
            if(get(this.postData, 'perfume')) {
                return get(this.postData, 'perfume.htmlUrl')
            }

            if(get(this.postData, 'brand')) {
                return get(this.postData, 'brand.htmlUrl')
            }

            if(get(this.postData, 'shop')) {
                return get(this.postData, 'shop.htmlUrl')
            }

            if(get(this.postData, 'article')) {
                return get(this.postData, 'article.htmlUrl')
            }

            if(get(this.postData, 'news')) {
                return get(this.postData, 'news.htmlUrl')
            }

            if(get(this.postData, 'post')) {
                return get(this.postData, 'post.htmlUrl')
            }

            return false
        },
        name() {
            return get(this.postData, 'createdBy.name', this.$t('guest'))
        },
        fullName() {
            return get(this.postData, 'createdBy.fullName', this.$t('guest'))
        },
        gender() {
            return get(this.postData, 'gender')
        },
        userProfile() {
            if(get(this.postData, 'article')) {
                return get(this.postData, 'article.htmlUrl')
            }

            if(get(this.postData, 'news')) {
                return get(this.postData, 'news.htmlUrl')
            }

            if(get(this.postData, 'post')) {
                return get(this.postData, 'post.htmlUrl')
            }

            return this.$router.resolve({
                name: 'users.detail',
                params: {
                    userId: this.postData.createdBy.id
                }
            }).href;
        },
        title() {
            return get(this.postData, 'name')
        },
        photo() {
            return get(this.postData, 'createdBy.photo')
        },
        rating() {
            return get(this.postData, 'stars')
        },
        text() {
            return get(this.postData, 'text')
        },
        likes() {
            return get(this.postData, 'ratings.likes', 0)
        },
        dislikes() {
            return get(this.postData, 'ratings.dislikes', 0)
        },
        pictures() {
            return get(this.postData, 'pictures', [])
        },
        authorPost() {
            if (this.noGender) {
                if(get(this.postData, 'article')) {
                    return get(this.postData, 'article.name')
                }

                if(get(this.postData, 'news')) {
                    return get(this.postData, 'news.name')
                }

                if(get(this.postData, 'post')) {
                    return get(this.postData, 'post.createdBy[0].fullName', '')
                }
            }

            if(get(this.postData, 'post')) {
                return get(this.postData, 'post.createdBy[0].fullName', '')
            }

            return get(this.postData, 'author.name') // ?
        },
        blockId() {
            return this.idPrefix + '_' + this.postData.id
        },
        scrollToBlock() {
            return '#' + this.blockId
        },
        scrollToReplies() {
            return '#' + this.repliesId
        },
        repliesId() {
            return this.idPrefix + '_' + 'replies' + this.blockId
        },
        repliesDeclension() {
            return [
                this.$t('replies_one'),
                this.$t('replies_few'),
                this.$t('replies_many')
            ]
        },
        buttonShowText() {
            const end = this.$options.filters.numberDeclension(this.countReplies, this.repliesDeclension)

            const startKey = this.isShowReplies ? 'hide_replies_start' : 'show_replies_start'
            const startStr = this.$t(startKey)

            return `${startStr} ${end}`
        },
        getReplies() {
            const replies = get(this.postData, 'replies', [])

            return orderBy(replies, reply => {
                return new Date(reply.createdAt)
            }, 'asc')
        },
        countReplies() {
            return this.replies.length
        },
        replyToEventName() {
            return 'reply-to-comment-' + this.blockId
        },
        commentToEventName() {
            return 'added-comment-to-' + get(this.postData, 'id')
        },
        ratingWithName() {
            return this.postType === 'user_review'
        },
        createdBy(){
            return get(this.postData, 'createdBy', null) || get(this.postData, 'post.createdBy[0]')
        },
        postDate(){
            const translations = {
                lastday_in: this.$t('date.lastday_in'),
                today_in: this.$t('date.today_in'),
                in: this.$t('date.in'),
            }

            return this.$options.filters.datePost(this.postData.createdAt, translations)
        }
    },
    beforeDestroy() {
        if (this.$bus._events[this.replyToEventName])
            this.$bus._events[this.replyToEventName] = []

        if (this.$bus._events[this.commentToEventName])
            this.$bus._events[this.commentToEventName] = []
    },
    created() {
        this.replies = this.getReplies

        if (process.client) {
            this.$bus.$on(this.replyToEventName, callback => {
                callback()
            })

            this.$bus.$on(this.commentToEventName, comment => {
                this.replies.push(comment);
                this.replies = this.getReplies;
                this.isShowReplies = true;
            })
        }

        this.isShowReplies = this.openReplies && this.replies && this.replies.length
    },
    methods: {
        get,
        triggerShowReplies: throttle(function() {
            this.isShowReplies = !this.isShowReplies

            if (this.isShowReplies) {
                this.$scrollTo(this.scrollToReplies, 750, {
                    offset: -56
                })
            } else {
                this.$scrollTo(this.scrollToBlock, 750, {
                    offset: -56
                })
            }
        }),
        showGallery(selectedSlide) {
            this.showPopup('comment-gallery', {
                pictures: this.pictures,
                name: this.name,
                photo: this.photo,
                selectedSlide
            })
        },
        replyTo() {
            this.$authEmit(this.replyToEventName, () => {

                this.showPopup('popup-comment', {
                    replyTo: get(this.postData, 'id'),
                    replyName: this.name,
                    code: get(this.reviewObject, 'code'),
                    page: this.reviewType
                })
            })
        },
        computedCaptionReply(gender) {
            const genderCaption = gender === 'female' ? this.$t('she_reply') : this.$t('he_reply')
            return genderCaption + ' ' + this.$t('for_comment') + ' ' + this.$t('for_user') + ' '
        }
    }
}
</script>

<style lang="less">
.feed-post {

    &__header {
        --photo-width: rem(40);
        --photo-height: rem(40);
    }

    &__title {
        font-size: rem(15);
        font-weight: 500;

        margin-bottom: rem(4);
    }

    &__content {
        padding-top: rem(16);
    }

    &__date{
        color: @color-gray;
        font-size: rem(13);
        line-height: rem(20);
        margin-top: rem(8);
    }

    .show-more__container {
        &>div {
            word-break: break-word;
        }
    }
}
</style>
