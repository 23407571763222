<i18n>
{
    "ru": {
        "main": "Главное на сегодня",
        "date": "5 октября",
        "new_perfumes": "Новые ароматы",
        "more_perfumes": "Все ароматы",
        "new_reviews": "Новые отзывы",
        "new_comments": "Новые комментарии",
        "new_video": "Новые видео",
        "all_videos": "Все видео",
        "new_users": "Новые пользователи",
        "all_users": "Все пользователи",
        "online_users": "Сейчас на сайте",
        "users_one": "пользователь",
        "users_few": "пользователя",
        "users_many": "пользователей",
        "guests_one": "гость",
        "guests_few": "гостя",
        "guests_many": "гостей",
        "and_more": "и ещё",
        "rubrics_articles": "Рубрики статей",
        "rubrics_news": "Рубрики новостей",
        "text_title": "Aromo: парфюмерная энциклопедия и не только",
        "text_text": "<p>Парфюмерная энциклопедия Aromo — это онлайн-проект о духах в России и мире. Мы объединили функции каталога, журнала и креативного пространства для независимых авторов так, чтобы у пользователей была возможность не только узнать все об ароматах, но и стать частью постоянно растущего сообщества.</p><p>Наши читатели — активные, заинтересованные, открытые новому и не боящиеся экспериментов любители парфюмерии и всего, что связано с темой обоняния. Для нас духи — и ежедневное удовольствие, и тема для вдумчивого, глубокого разговора; мы пишем о том, какую роль играют ароматы в истории, просто отвечаем на сложные вопросы и честно рассказываем, на какие новинки стоит обратить внимание, а какие можно обойти стороной.</p><p>Aromo говорит о парфюмерии понятным языком и смотрит на нее современным взглядом: в нашем понимании это не средство соблазнения, но один из способов сделать жизнь красивее и объемнее — такой же, как искусство, хорошая литература и высокая гастрономия.</p>",
        "show_more": "Показать больше",
        "persons": "Интересные персоны",
        "all_persons": "Все персоны",
        "popular_brands": "Популярные бренды",
        "all_brands": "Все бренды",
        "popular_notes": "Популярные ноты",
        "all_notes": "Все ноты",
        "popular_perfumes": "Популярные ароматы",
        "all_perfumes": "Все ароматы",
        "popular_sets": "Популярные подборки",
        "all_sets": "Все подборки ароматов"
    }
}
</i18n>
<template>
    <div class="home-page">
        <SectionCard class="home-page__card">
            <template #main>

                <!-- main slider -->
                <div class="pre-placeholder home-page__slider-wrap" :style="sliderBg">
                    <PageGallery
                        class="home-page__gallery"
                        show-dots
                        picture-property="cover"
                        :show-navigation="isDeviceDesktop"
                        transparent-dots
                        loop
                        :follow-finger="isDeviceDesktop"
                        autoplay
                        v-if="blockSlider && blockSlider.length"
                        :items="blockSlider"/>
                </div>

                <!-- families -->
                <LazyHydrate v-if="computedFamilies.length > 0" when-visible>
                    <PageBlock
                        class="home-page__families"
                        :content-visible="isDeviceDesktop">

                        <BlockPersonsRow
                            :swiper="isDeviceDesktop"
                            :space-between="8"
                            :loop="false"
                            detectEdges
                            :items="computedFamilies"
                            class="home-page__families-items">

                            <template #item="{ item }" >
                                <BlockBorderedWithImage
                                    :html-url="item.htmlUrl"
                                    :type="'family'"
                                    :image-url="get(item, 'picture.url', null)"
                                    :title="item.name" />
                            </template>

                        </BlockPersonsRow>
                    </PageBlock>
                </LazyHydrate>

                <!-- new perfumes -->
                <LazyHydrate v-if="blockNewPerfumes.length > 0" when-visible>
                    <PageBlock
                        class="home-page__new-perfumes"
                        :big-title="isDeviceDesktop"
                        :content-visible="isDeviceDesktop"
                        :title="$t('new_perfumes')">
                        <BlockPersonsRow
                            :swiper="isDeviceDesktop"
                            :slides-per-view="12"
                            :space-between="19"
                            :loop="false"
                            :items="blockNewPerfumes">
                            <template #item="{ item }" >
                                <BlockOfferPictureOnly :offer-data="item" />
                            </template>
                        </BlockPersonsRow>
                    </PageBlock>
                </LazyHydrate>

                <!-- main today -->
                <LazyHydrate v-if="blockMainToday.length > 0" when-visible>
                    <PageBlock
                        :title="$t('main')"
                        :big-title="isDeviceDesktop"
                        :content-visible="isDeviceDesktop"
                        class="home-page__posts"
                        :counter="getDate">

                        <PostsGrid
                            v-if="isDeviceDesktop"
                            hide-slots
                            only-layout
                            no-pagination
                            :items="blockMainToday" />

                        <PostsList
                            v-else
                            no-pagination
                            preload-placeholder
                            :items="blockMainToday" />

                    </PageBlock>
                </LazyHydrate>

                <!-- popular perfumes -->
                <div class="app__delayed-block app__delayed-block_popular-perfumes"
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.popularPerfumes && get(delayedData, 'popularPerfumes.items', []).length > 0}"
                    data-key="popularPerfumes"
                    :data-loading="!delayedData.popularPerfumes && delayedDataLoading.popularPerfumes">
                    <template v-if="delayedData.popularPerfumes && get(delayedData, 'popularPerfumes.items', []).length > 0">
                        <PageBlock
                            v-if="isDeviceDesktop"
                            content-visible
                            :big-title="isDeviceDesktop"
                            :class="'page-block_no-margin'"
                            :title="$t('popular_perfumes')">
                            <template #title-button>
                                <html-link
                                    class="home-page__link"
                                    to="/perfumes/">{{$t('all_perfumes')}}</html-link>
                            </template>
                            <BlockPersonsRow
                                class="home-page__scents"
                                swiper
                                :slides-per-view="6"
                                :space-between="0"
                                :loop="false"
                                :items="delayedData.popularPerfumes.items">
                                <template #item="{ item }" >
                                    <BlockOffer
                                        :name="get(item, 'name')"
                                        :url="get(item, 'htmlUrl')"
                                        :picture="get(item, 'picture.url')"
                                        :alt="get(item, 'picture.alt')"
                                        :brand-name="get(item, 'brand.name')"
                                        :with-comment="false"
                                        direction-column
                                        withRating
                                        :rating="get(item, 'ratings.value', 0)"
                                        :caption="get(item, 'concentration.name', 0)"/>
                                </template>
                            </BlockPersonsRow>
                        </PageBlock>

                        <PageBlock v-else :title="$t('popular_perfumes')">
                            <PerfumesSlider
                                :items="delayedData.popularPerfumes.items"
                                withRating />
                            <html-link
                                class="home-page__button-more home-page__button-more_chevron"
                                centered
                                to="/perfumes/">
                                {{ $t('all_perfumes') }}
                                <svg-icon class="home-page__button-more-chevron" name="chevron-right-gray" />
                            </html-link>
                        </PageBlock>
                    </template>
                </div>

                <!-- popular sets -->
                <div class="app__delayed-block app__delayed-block_popular-sets"
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.popularSets && delayedData.popularSets.length > 0}"
                    data-key="popularSets"
                    :data-loading="!delayedData.popularSets && delayedDataLoading.popularSets">
                    <template v-if="delayedData.popularSets && delayedData.popularSets.length > 0">
                        <PageBlock
                            class="home-page__sets"
                            v-if="isDeviceDesktop"
                            content-visible
                            :big-title="isDeviceDesktop"
                            :title="$t('popular_sets')">

                            <template #title-button>
                                <html-link
                                    class="home-page__link"
                                    to="/sets/">{{$t('all_sets')}}</html-link>
                            </template>

                            <div class="home-page__sets-items">
                                <div v-for="(set, index) in delayedData.popularSets"
                                    class="home-page__sets-item" 
                                    :key="index">
                                    <a :href="set.htmlUrl" class="home-page-set">
                                        <span class="home-page-set__picture">
                                            <img v-lazy-load :data-src="set.picture.url" :alt="set.name" />
                                        </span>
                                        {{ set.name }}
                                    </a>
                                </div>
                            </div>
                        </PageBlock>

                        <PageBlock
                            v-else
                            :title="$t('popular_sets')">
                            <RubricsList :items="delayedData.popularSets"/>
                            <html-link
                                class="home-page__button-more home-page__button-more_chevron"
                                centered
                                to="/sets/">
                                {{ $t('all_sets') }}
                                <svg-icon class="home-page__button-more-chevron" name="chevron-right-gray" />
                            </html-link>
                        </PageBlock>
                    </template>
                </div>

                <!-- new reviews -->
                <div class="home-page__reviews">
                    <div class="app__delayed-block app__delayed-block_reviews" 
                        v-observe-visibility="delayedDataObserver"
                        :class="{loaded: delayedData.newReviews && delayedData.newReviews.length > 0}"
                        data-key="newReviews"
                        :data-loading="!delayedData.newReviews && delayedDataLoading.newReviews">
                        <template v-if="delayedData.newReviews && delayedData.newReviews.length > 1">
                            <PageBlock
                                :big-title="isDeviceDesktop"
                                :title="$t('new_reviews')">
                                <ReviewsSlider :items="delayedData.newReviews"/>
                            </PageBlock>
                        </template>
                    </div>
                    <div class="app__delayed-block app__delayed-block_reviews" 
                        v-observe-visibility="delayedDataObserver"
                        :class="{loaded: delayedData.newComments && delayedData.newComments.length > 0}"
                        data-key="newComments"
                        :data-loading="!delayedData.newComments && delayedDataLoading.newComments">
                        <template v-if="delayedData.newComments && delayedData.newComments.length > 1">
                            <PageBlock
                                :big-title="isDeviceDesktop"
                                :title="$t('new_comments')">
                                <ReviewsSlider :items="delayedData.newComments"/>
                            </PageBlock>
                        </template>
                    </div>
                </div>

                <!-- recommendations -->
                <div class="app__delayed-block app__delayed-block_recommends" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.recommends && delayedData.recommends.length > 0}"
                    data-key="recommends"
                    :data-loading="!delayedData.recommends && delayedDataLoading.recommends">
                    <template v-if="delayedData.recommends && delayedData.recommends.length > 1">
                        <div class="home-page__subscribe-wrapper">
                            <PageBlock>
                                <CollectionsList :items="delayedData.recommends"/>
                            </PageBlock>
                            <PageBlock>
                                <BlockSubscribe />
                            </PageBlock>
                        </div>
                    </template>
                </div>

                <!-- new users -->
                <div class="app__delayed-block app__delayed-block_new-users" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.newUsers && delayedData.newUsers.length > 0}"
                    data-key="newUsers"
                    :data-loading="!delayedData.newUsers && delayedDataLoading.newUsers">
                    <template v-if="delayedData.newUsers && delayedData.newUsers.length > 0">
                        <PageBlock
                            class="home-page__wearers"
                            :big-title="isDeviceDesktop"
                            :content-visible="isDeviceDesktop"
                            :title="$t('new_users')">
                            <BlockPersonsRow
                                :swiper="isDeviceDesktop"
                                :slides-per-view="6"
                                :space-between="0"
                                :loop="false"
                                :items="delayedData.newUsers"
                                class="home-page__wearers-items">
                                <template #item="{ item }" >
                                    <BlockPersonCard
                                        :no-border="isDeviceDesktop"
                                        :user-data="item" />
                                </template>
                            </BlockPersonsRow>
                            <router-link 
                                :to="{name: 'Community'}"
                                v-if="isDeviceDesktop"
                                class="home-page__wearers-all">
                                {{ $t('all_users') }}
                            </router-link>
                            <router-link
                                v-if="!isDeviceDesktop"
                                :to="{name: 'Community'}"
                                class="home-page__button-more home-page__button-more_chevron">
                                {{ $t('all_users') }}
                                <svg-icon class="home-page__button-more-chevron" name="chevron-right-gray" />
                            </router-link>
                        </PageBlock>
                    </template>
                </div>

                <!-- online users -->
                <!-- <div class="app__delayed-block" 
                    v-observe-visibility="delayedDataObserver"
                    data-key="onlineUsers"
                    :data-loading="!delayedData.onlineUsers && delayedDataLoading.onlineUsers">
                    <template v-if="delayedData.onlineUsers && get(delayedData, 'onlineUsers.items.length', 0) > 0">
                        <PageBlock
                            class="home-page__online-users home-page-online-users"
                            :big-title="isDeviceDesktop"
                            :content-visible="isDeviceDesktop"
                            :title="$t('online_users')"
                            :counter="onlineUsersCounter(delayedData.onlineUsers.total)">

                            <div class="home-page-online-users__wrapper">
                                <div v-for="(user, index) in delayedData.onlineUsers.items"
                                    class="home-page-online-users__item"
                                    :key="index">
                                    <BlockBorderedWithImage
                                        :html-url="user.htmlUrl"
                                        :image-url="get(user, 'photo.url', null)"
                                        :title="user.fullName" />
                                </div>
                            </div>
                            <div v-if="onlineUsersNextPageUrl !== 'end'"
                                class="home-page-online-users__more">
                                <ButtonMore
                                    centered
                                    :data-loading="onlineUsersLoading"
                                    :buttonIconName="'icon-chevron-down'"
                                    @click.native.prevent="loadMoreOnlineUsers">
                                    {{ $t('show_more') }}
                                </ButtonMore>
                            </div>
                        </PageBlock>
                    </template>
                </div> -->

                <!-- first articles section -->
                <div class="app__delayed-block app__delayed-block_articles" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0}"
                    data-key="newsAndArticles"
                    :data-loading="!delayedData.newsAndArticles && delayedDataLoading.newsAndArticles">
                    <template v-if="delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0">
                        <PageBlock
                            :no-margin="isDeviceDesktop"
                            :content-visible="isDeviceDesktop">

                            <PostsGrid
                                v-if="isDeviceDesktop"
                                only-layout
                                hide-slots
                                no-pagination
                                :items="articlesSections[0]" />

                            <PostsList
                                v-else
                                no-pagination
                                :items="articlesSections[0]" />

                        </PageBlock>
                    </template>
                </div>

                <!-- popular persons -->
                <div class="app__delayed-block app__delayed-block_new-users" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.popularPersons && get(delayedData, 'popularPersons.items', []).length > 0 > 0}"
                    data-key="popularPersons"
                    :data-loading="!delayedData.popularPersons && delayedDataLoading.popularPersons">
                    <template v-if="delayedData.popularPersons && get(delayedData, 'popularPersons.items', []).length > 0">
                        <PageBlock
                            class="home-page__wearers"
                            :big-title="isDeviceDesktop"
                            :content-visible="isDeviceDesktop"
                            :title="$t('persons')">

                            <BlockPersonsRow
                                :swiper="isDeviceDesktop"
                                :slides-per-view="6"
                                :space-between="0"
                                :loop="false"
                                :items="delayedData.popularPersons.items"
                                class="home-page__wearers-items">

                                <template #item="{ item }" >
                                    <BlockPersonCard
                                        isPerson
                                        :class="'block-person-card_main'"
                                        :no-border="isDeviceDesktop"
                                        :user-data="item" />
                                </template>
                            </BlockPersonsRow>
                            <router-link 
                                :to="{name: 'Noses'}"
                                v-if="isDeviceDesktop"
                                class="home-page__wearers-all">
                                {{ $t('all_persons') }}
                            </router-link>
                            <router-link
                                v-if="!isDeviceDesktop"
                                :to="{name: 'Noses'}"
                                class="home-page__button-more home-page__button-more_chevron">
                                {{ $t('all_persons') }}
                                <svg-icon class="home-page__button-more-chevron" name="chevron-right-gray" />
                            </router-link>
                        </PageBlock>
                    </template>
                </div>

                <!-- second articles section -->
                <div class="app__delayed-block app__delayed-block_articles" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0}"
                    data-key="newsAndArticles"
                    :data-loading="!delayedData.newsAndArticles && delayedDataLoading.newsAndArticles">
                    <template v-if="delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0">
                        <PageBlock
                            :no-margin="isDeviceDesktop"
                            :content-visible="isDeviceDesktop">

                            <PostsGrid
                                v-if="isDeviceDesktop"
                                only-layout
                                hide-slots
                                no-pagination
                                :items="articlesSections[1]" />

                            <PostsList
                                v-else
                                no-pagination
                                :items="articlesSections[1]" />

                        </PageBlock>
                    </template>
                </div>

                <!-- popular brands -->
                <div class="app__delayed-block app__delayed-block_new-users" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.popularBrands && get(delayedData, 'popularBrands.items', []).length > 0 > 0}"
                    data-key="popularBrands"
                    :data-loading="!delayedData.popularBrands && delayedDataLoading.popularBrands">
                    <template v-if="delayedData.popularBrands && get(delayedData, 'popularBrands.items', []).length > 0">
                        <PageBlock
                            class="home-page__wearers"
                            :big-title="isDeviceDesktop"
                            :content-visible="isDeviceDesktop"
                            :title="$t('popular_brands')">
                            <BlockPersonsRow
                                :swiper="isDeviceDesktop"
                                :slides-per-view="6"
                                :space-between="0"
                                :loop="false"
                                :items="delayedData.popularBrands.items"
                                class="home-page__wearers-items">
                                <template #item="{ item }" >
                                    <BlockPersonCard
                                        :class="'block-person-card_main'"
                                        :no-border="isDeviceDesktop"
                                        isBrand
                                        :user-data="item" />
                                </template>
                            </BlockPersonsRow>
                            <router-link 
                                :to="{name: 'Brands'}"
                                v-if="isDeviceDesktop"
                                class="home-page__wearers-all">
                                {{ $t('all_brands') }}
                            </router-link>
                            <router-link
                                v-if="!isDeviceDesktop"
                                :to="{name: 'Brands'}"
                                class="home-page__button-more home-page__button-more_chevron">
                                {{ $t('all_brands') }}
                                <svg-icon class="home-page__button-more-chevron" name="chevron-right-gray" />
                            </router-link>
                        </PageBlock>
                    </template>
                </div>

                <!-- third articles section -->
                <div class="app__delayed-block app__delayed-block_articles" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0}"
                    data-key="newsAndArticles"
                    :data-loading="!delayedData.newsAndArticles && delayedDataLoading.newsAndArticles">
                    <template v-if="delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0">
                        <PageBlock
                            :no-margin="isDeviceDesktop"
                            :content-visible="isDeviceDesktop">

                            <PostsGrid
                                v-if="isDeviceDesktop"
                                only-layout
                                hide-slots
                                no-pagination
                                :items="articlesSections[2]" />

                            <PostsList
                                v-else
                                no-pagination
                                :items="articlesSections[2]" />

                        </PageBlock>
                    </template>
                </div>

                <!-- popular notes -->
                <div class="app__delayed-block app__delayed-block_new-users" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.popularNotes && get(delayedData, 'popularNotes.items', []).length > 0 > 0}"
                    data-key="popularNotes"
                    :data-loading="!delayedData.popularNotes && delayedDataLoading.popularNotes">
                    <template v-if="delayedData.popularNotes && get(delayedData, 'popularNotes.items', []).length > 0">
                        <PageBlock
                            class="home-page__wearers"
                            :big-title="isDeviceDesktop"
                            :content-visible="isDeviceDesktop"
                            :title="$t('popular_notes')">
                            <BlockPersonsRow
                                :swiper="isDeviceDesktop"
                                :slides-per-view="6"
                                :space-between="0"
                                :loop="false"
                                :items="delayedData.popularNotes.items"
                                class="home-page__wearers-items">
                                <template #item="{ item }" >
                                    <BlockPersonCard
                                        :class="'block-person-card_main'"
                                        :no-border="isDeviceDesktop"
                                        isNote
                                        :user-data="item" />
                                </template>
                            </BlockPersonsRow>
                            <router-link 
                                :to="{name: 'Notes'}"
                                v-if="isDeviceDesktop"
                                class="home-page__wearers-all">
                                {{ $t('all_notes') }}
                            </router-link>
                            <router-link
                                v-if="!isDeviceDesktop"
                                :to="{name: 'Notes'}"
                                class="home-page__button-more home-page__button-more_chevron">
                                {{ $t('all_notes') }}
                                <svg-icon class="home-page__button-more-chevron" name="chevron-right-gray" />
                            </router-link>
                        </PageBlock>
                    </template>
                </div>

                <!-- fourth and fith sections -->
                <div class="app__delayed-block app__delayed-block_articles-big" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0}"
                    data-key="newsAndArticles"
                    :data-loading="!delayedData.newsAndArticles && delayedDataLoading.newsAndArticles">
                    <template v-if="delayedData.newsAndArticles && delayedData.newsAndArticles.length > 0">
                        <PageBlock
                            :no-margin="isDeviceDesktop"
                            :content-visible="isDeviceDesktop">

                            <PostsGrid
                                v-if="isDeviceDesktop"
                                only-layout
                                hide-slots
                                no-pagination
                                :items="articlesSections[3].concat(articlesSections[4])" />

                            <PostsList
                                v-else
                                no-pagination
                                :items="articlesSections[3].concat(articlesSections[4])" />

                        </PageBlock>
                    </template>
                </div>

                <!-- news rubrics -->
                <div class="app__delayed-block app__delayed-block_news-rubrics home-page__rubrics home-page__rubrics_news" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.newsRubrics && delayedData.newsRubrics.length > 0}"
                    data-key="newsRubrics"
                    :data-loading="!delayedData.newsRubrics && delayedDataLoading.newsRubrics">
                    <template v-if="delayedData.newsRubrics && delayedData.newsRubrics.length > 0">
                        <PageBlock
                            :big-title="isDeviceDesktop"
                            :title="$t('rubrics_news')">
                            <RubricsList :items="delayedData.newsRubrics"/>
                        </PageBlock>
                    </template>
                </div>

                <!-- articles rubrics -->
                <div class="app__delayed-block app__delayed-block_articles-rubrics home-page__rubrics" 
                    v-observe-visibility="delayedDataObserver"
                    :class="{loaded: delayedData.articlesRubrics && delayedData.articlesRubrics.length > 0}"
                    data-key="articlesRubrics"
                    :data-loading="!delayedData.articlesRubrics && delayedDataLoading.articlesRubrics">
                    <template v-if="delayedData.articlesRubrics && delayedData.articlesRubrics.length > 0">
                        <PageBlock
                            :big-title="isDeviceDesktop"
                            :title="$t('rubrics_articles')">
                            <RubricsList :items="delayedData.articlesRubrics"/>
                        </PageBlock>
                    </template>
                </div>

                <!-- description -->
                <LazyHydrate when-visible>
                    <PageBlock
                        :big-title="isDeviceDesktop"
                        :title="$t('text_title')"
                        class="home-page__text"
                        :heading-tag="'h1'">

                        <div class="page-block__description-wrapper">
                            <div class="page-block__description">
                                <!-- eslint-disable-next-line -->
                                <div v-html="$t('text_text')"></div>
                            </div>
                        </div>

                    </PageBlock>
                </LazyHydrate>

            </template>
        </SectionCard>
    </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import chunk from 'lodash/chunk'

import dayjs from 'dayjs';

import LazyHydrate from '@/modules/LazyHydrate';

import SectionCard from '@/components/layout/AppSectionCard';
import PageBlock from '@/components/layout/AppPageBlock';
import PageGallery from '@/components/layout/AppPageGallery';
import PostsList from '@/components/layout/blocks/PostsList';
import PostsGrid from '@/components/layout/blocks/PostsGrid';
import BlockSubscribe from '@/components/forms/BlockSubscribe'
import BlockPersonsRow from '@/components/catalog/persons/BlockPersonsRow';
import BlockPersonCard from '@/components/catalog/persons/BlockPersonCard';
import CollectionsList from '@/components/layout/blocks/CollectionsList'
import PerfumesSlider from '@/components/layout/blocks/PerfumesSlider'
import ReviewsSlider from '@/components/layout/blocks/ReviewsSlider'
import RubricsList from '@/components/layout/blocks/RubricsList';
import BlockOffer from '@/components/catalog/BlockOffer';
import BlockBorderedWithImage from '@/components/layout/blocks/BlockBorderedWithImage';
import BlockOfferPictureOnly from '@/components/catalog/BlockOfferPictureOnly';

import Complex from '@/models/complex/'
import MainPage from '@/models/complex/MainPage'
const customParseFormat = require('dayjs/plugin/customParseFormat')

require('dayjs/locale/ru');

export default {
    name: 'Home',
    components: {
        BlockSubscribe,
        PerfumesSlider,
        ReviewsSlider,
        CollectionsList,
        BlockPersonCard,
        BlockPersonsRow,
        SectionCard,
        PageBlock,
        PageGallery,
        PostsList,
        PostsGrid,
        BlockOffer,
        RubricsList,
        LazyHydrate,
        BlockBorderedWithImage,
        BlockOfferPictureOnly
    },
    directives: {
        'observe-visibility': ObserveVisibility
    },
    fetchOnServer: false,
    fetch() {
    },
    asyncData({ $ua, params, $auth, app, query, error }) {
        let requests = []

        const mainPageModel = new MainPage().for(new Complex)

        requests = [
            mainPageModel.get(),
            app.$api.get('/groups?sort=amount_perfumes&order=desc'),
            app.$api.get('/complex/new-perfumes'),
            app.$api.get('/complex/main-today-contents')
        ];

        return Promise.all(requests).then(results => {
            const content = get(results, '[0][0]', []);
            content.blockFamilies = get(results, '[1].data.items', []);
            content.blockNewPerfumes = get(results, '[2].data', []);
            content.blockMainToday = get(results, '[3].data', []);

            if (process.server) {
                app.store.dispatch('rest/headers/filterLastModified', {
                    url: [
                        `/complex/main-page`,
                        `/complex/main-today-contents`,
                        `/complex/new-perfumes`,
                        `/groups`
                    ]
                });
            }

            return content;
        }).catch((e) => {
            error({statusCode: e.response.status, message: e.response.data});
        });
    },
    data() {
        return {
            blockSlider: [],
            blockFamilies: [],
            blockNewPerfumes: [],
            blockMainToday: [],
            sliderBg: {
                'background-image': null
            },
            delayedDataObserver: {
                callback: this.onDelayedDataVisible,
                throttle: 1000,
                once: true,
                throttleOptions: {
                    leading: 'visible',
                },
                intersection: {
                    threshold: 0,
                    rootMargin: this.isDeviceDesktop ? '400px' : '200px'
                }
            },
            delayedData: {
                newPerfumes: null,
                mainToday: null,
                onlineUsers: null,
                newReviews: null,
                newComments: null,
                recommends: null,
                newUsers: null,
                newsAndArticles: null,
                newsRubrics: null,
                articlesRubrics: null,
                popularPerfumes: null,
                families: null,
                popularPersons: null,
                popularBrands: null,
                popularNotes: null,
                popularSets: null
            },
            delayedDataLoading: {},
            onlineUsersInitPerPage: 20,
            onlineUsersNextPageUrl: null,
            onlineUsersLoading: false
        }
    },
    provide() {
        return {
            singlePost: {
                code: null
            }
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        getDate() {
            dayjs.extend(customParseFormat);
            return dayjs().locale(this.$i18n.locale).format('D MMMM')
        },
        articlesSections(){
            return chunk(this.delayedData.newsAndArticles, 7)
        },
        computedFamilies(){
            return this.blockFamilies.map(family => {
                family.htmlUrl = `${this.$i18n.__baseUrl}/perfumes/gr-${family.code}/`
                return family
            })
        }
    },
    created(){
        const bg = get(this.blockSlider, '[0].cover.url');

        if (bg) {
            this.sliderBg['background-image'] = 'url(' + bg + ')';
        }
    },
    methods: {
        get,
        onDelayedDataVisible(isVisible, entry, customArgument) {
            if (isVisible) {
                const key = entry.target.getAttribute('data-key');
                let url;

                if (!this.delayedDataLoading[key]) {
                    this.delayedDataLoading[key] = true;

                    switch (key) {
                    case 'newPerfumes':
                        url = `/complex/new-perfumes`;
                        break;
                    case 'mainToday':
                        url = `/complex/main-today-contents`;
                        break;
                    case 'onlineUsers':
                        // url = `/users?is_online=y`;
                        url = `/users?per_page=${this.onlineUsersInitPerPage}`;
                        break;
                    case 'newReviews':
                        url = `/complex/new-reviews`;
                        break;
                    case 'newComments':
                        url = `/complex/new-comments`;
                        break;
                    case 'recommends':
                        url = `/complex/recommended-contents`;
                        break;
                    case 'newUsers':
                        url = `/complex/new-users`;
                        break;
                    case 'newsAndArticles':
                        url = `/complex/news-and-articles`;
                        break;
                    case 'newsRubrics':
                        url = `/complex/news-sections`;
                        break;
                    case 'articlesRubrics':
                        url = `/complex/articles-sections`;
                        break;
                    case 'popularPerfumes':
                        url = `/perfumes?per_page=20`;
                        break;
                    case 'families':
                        url = `/groups?sort=amount_perfumes&order=desc`;
                        break;
                    case 'popularPersons':
                        url = `/perfumers?per_page=20`;
                        break;
                    case 'popularBrands':
                        url = `/brands?per_page=20`;
                        break;
                    case 'popularNotes':
                        url = `/notes?per_page=20`;
                        break;
                    case 'popularSets':
                        url = `/complex/popular-sets`;
                        break;
                    }

                    if (url) {
                        this.$api.get(url)
                            .then((response) => {
                                this.delayedData[key] = response.data;
                            });
                    }
                }
            }
        },
        onlineUsersCounter(length){
            const counter = length || 0
            const declensionArray = [
                this.$t('users_one'),
                this.$t('users_few'),
                this.$t('users_many')
            ]
            return this.$options.filters.numberDeclension(counter, declensionArray)
        },
        async loadMoreOnlineUsers(){
            if(!this.onlineUsersNextPageUrl)
                this.onlineUsersNextPageUrl = `/users?per_page=${this.onlineUsersInitPerPage}&page=2`
            
            if(this.onlineUsersNextPageUrl !== 'end'){
                this.onlineUsersLoading = true
                await this.$api.get(this.onlineUsersNextPageUrl)
                    .then((response) => {
                        this.onlineUsersNextPageUrl = get(response, 'data.nextPageUrl', 'end')
                        this.delayedData.onlineUsers.items = this.delayedData.onlineUsers.items.concat(get(response, 'data.items'))
                    })
                this.onlineUsersLoading = false
            }
        }
    },
    head() {
        return {
            title: get(this.meta, 'title'),
            meta: this.$options.filters.metaApiExtract({meta: this.meta})
        }
    }
}
</script>

<style lang="less">
.home-page {
    background-color: @color-bg-gray;

    @{desktop} & {
        background-color: @color-white;
    }

    .app__delayed-block{
        opacity: .25;
        will-change: opacity;
        transition: opacity .75s ease;

        &.loaded{
            @{desktop} & ,
            @{mobile} & {
                opacity: 1;
                height: auto;
            }
        }
    }

    &__slider-wrap{
        @{mobile} & {
            height: rem(250);
            position: relative;
        }

        @{desktop} & {
            margin-top: rem(32);
            margin-bottom: rem(40);
        }
    }

    &__families{
        
        &.page-block{
            margin-bottom: rem(24);
        }

        @{mobile} & {
            padding-top: rem(20);
            
            &.page-block{
                margin-bottom: 0;
            }
        }

        @{desktop} & {
            .block-persons-row{
                .swiper-wrapper,
                .swiper-container{
                    padding: 0 !important;
                }
            }
        }

        &-items{
            position: relative;

            &.block-persons-row{
                padding: 0;
            }

            .block-persons-row__swiper-slider{

                &::after,
                &::before{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    content: '';
                    width: rem(31);
                    pointer-events: none;
                    z-index: 9;
                }

                &::before{
                    left: 0;
                    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, white 100%);
                }

                &::after{
                    right: 0;
                    background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
                }

                &_start{
                    &::before{
                        display: none;
                    }
                }

                &_end{
                    &::after{
                        display: none;
                    }
                }
            }

            .swiper-wrapper{
                position: static;
            }

            .rounded-block{
                margin-bottom: 0;
            }

            .swiper-container-initialized{
                .swiper-slide{
                    @{desktop} & {
                        margin-right: 0;
                    }
                }
            }

            .block-persons-row{
                &__slide{
                    width: auto;
                    padding-right: rem(8);

                    &:last-child{
                        padding-right: rem(20);
                    }
                }

                &__slider{
                    margin: 0;
                }
            }

            @{desktop} & {
                .swiper-slide{
                    padding: 0;
                    width: auto;
                    margin-right: rem(8);
                }

                .swiper-wrapper,
                .swiper-container{
                    padding: 0;
                }

                .block-persons-row__button{
                    height: rem(44);
                    top: 0;
                }
            }
        }
    }

    &__new-perfumes{
        margin-bottom: 0;
        
        @{mobile} & {
            padding-bottom: rem(17);
        }

        .page-block{
            &__header{
                padding-bottom: rem(11);

                .@{desktop} & {
                    padding-bottom: rem(16);
                }
            }

            &__content{
                margin-top: rem(10);
            }
        }

        .block-persons-row{
            &__slide{
                width: auto;
                padding-right: rem(16);

                &:last-child{
                    padding-right: 0;
                }
            }

            &__slider{
                margin: 0;
            }
        }

        @{desktop} & {
            margin-bottom: rem(40);

            .swiper-slide{
                padding: 0;
                width: auto;
            }

            .swiper-wrapper,
            .swiper-container{
                padding: 0;
            }

            .block-persons-row{
                padding: 0;

                &__button{
                    height: rem(71);
                    top: 0;
                }

                .swiper-wrapper{
                    padding: 0;
                }
            }
        }
    }

    &__gallery {
        @{mobile} & {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &.swiper-container{
            @{mobile} & {
                position: absolute;
            }
        }
    }

    &__card {
        &.section-card{
            background: none;
        }
    }

    &__button-more {
        display: block;

        background: none;
        border-top: rem(1) solid @color-gray-white;
        color: @color-violet;
        outline: none;
        text-align: center;

        margin-top: rem(8);
        padding: rem(12) 0 rem(12);

        width: 100%;

        &_chevron {
            position: relative;
            text-align: left;
            margin-top: 0;
            .page-padding();
        }

        &-chevron {
            position: absolute;
            width: rem(16);
            height: rem(16);
            right: rem(16);
            top: rem(16);
        }
    }

    &__posts {

        @{desktop} & {
            margin-bottom: rem(-10);

            .page-block__header {
                padding-bottom: rem(16);
            }
        }
    }

    &__rubrics {
        &_news{
            @{desktop} & {
                .page-block{
                    margin-bottom: rem(64);
                }
            }
        }
    }

    &__reviews {
        @{desktop} & {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - rem(14)));
            column-gap: rem(28);
            margin-bottom: rem(48);

            .page-block,
            .app__delayed-block{
                display: flex;
                flex-direction: column;
            }

            .page-block{
                height: 100%;

                &__content{
                    flex: 1;
                }
            }

            .reviews-slider {
                border: rem(1) solid @color-gray-white;
                padding: 0 rem(20);
                min-height: 100%;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            .page-block__header {
                margin-bottom: rem(8);
            }
        }
    }

    &__subscribe-wrapper {
        @{desktop} & {
            display: grid;
            grid-template-areas:
                "wide wide small";
            grid-template-rows: auto;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: min-content;
            gap: rem(64) rem(28);
            margin-bottom: rem(64);

            .page-block {
                margin-bottom: 0;

                &, &__content {
                    height: 100%;
                }
            }

            .collections-list, .block-subscribe {
                height: 100%;
            }

            .page-block:first-child {
                grid-area: wide;
            }

            .page-block:nth-child(2) {
                grid-area: small;
            }
        }
    }

    &__wearers {

        &-items{
            margin-top: 5px;

            @{desktop} &{
                margin-top: 2px;
            }
        }

        .scroll-slider{
            padding-left: rem(18);
            padding-right: rem(18);
        }

        @{desktop} & {
            margin-bottom: rem(54);
            // margin-top: rem(54);

            // .block-persons-row .swiper-container{
            //     padding-left: 0;
            //     padding-right: 0;
            // }

            .block-persons-row .swiper-slide{
                width: 100% / 6;
            }
        }

        &-all{
            display: block;
            font-size: rem(15);
            text-align: center;

            @{desktop} & {
                position: absolute;
                right: 0;
                top: rem(-34);
                line-height: 1
            }

            &:hover{
                text-decoration: underline;
            }
        }
    }

    &__text{
        @{desktop} &{
            margin-top: rem(54);
        }
    }

    &__link {
        color: @color-primary;

        position: relative;
        top: rem(3);

        .hover({
            text-decoration: underline;
        });
    }

    &__scents{
        @{desktop} & {
            height: rem(286);
        }
    }

    .block-article__likes {
        pointer-events: none;
    }

    .collections-list {
        border: none;
    }

    &__online-users{
        .@{desktop} & {
            margin-bottom: rem(52);
        }
    }

    &__sets{

        &.page-block{
            margin-bottom: rem(56);
        }

        .page-block__header{
            margin-bottom: rem(10);
        }
        
        &-items{
            display: flex;
            margin: 0 rem(-10);
        }

        &-item{
            padding: 0 rem(10);
            min-width: 0;
            flex: 0 0 33.333%;
        }
    }
}

.home-page-online-users{

    @{mobile} & {
        &__wrapper{
            .show-more__wrapper_no-collapsible{
                margin-bottom: rem(16);
            }
        }
    }

    .page-block__header{
        padding-bottom: rem(12);

        .@{desktop} & {
            padding-bottom: rem(16);
        }
    }

    .show-more__container,
    &__wrapper{
        display: flex;
        flex-wrap: wrap;

        @{mobile} & {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

    &__item{
        margin: 0 rem(8) rem(8) 0;
        max-width: rem(250);

        &_more{
            font-size: rem(13);
            line-height: rem(20);
            padding-top: rem(13);

            @{mobile} & {
                padding-top: rem(7);
            }
        }

        @{mobile} & {
            margin-right: rem(12);
            margin-bottom: 0;
        }
    }

    &__more{
        position: relative;

        &:after {
            content: '';
            display: block;
            background: linear-gradient(360deg, white 0%, rgba(255, 255, 255, 0) 100%);
            height: rem(50);
            width: 100%;
            max-height: rem(50);
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 1;
            pointer-events: none;
        }

        .button-more{
            &[data-loading="true"]{
                pointer-events: none;

                &::before{
                    background: url('~static/img/loading-btn-transparent.gif') no-repeat 50%;
                    background-color: @color-bg-gray;
                    background-size: rem(24);
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: '';
                    position: absolute;

                    @{mobile} & {
                        background-color: white;
                    }
                }
            }
        }
    }
}

.home-page-set{
    display: block;
    padding: rem(20);
    text-align: center;
    font-size: rem(15);
    line-height: rem(23);
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: @color-white;
    color: @color-black;
    border: rem(1) solid @color-gray-white;
    border-radius: rem(3);
    .animate(box-shadow);

    &:hover{
        box-shadow: @menu-desktop-box-shadow;
    }

    &__picture{
        display: block;
        margin: 0 auto rem(16);
        width: rem(104);
        height: rem(104);
        border-radius: 100%;
        overflow: hidden;
        backface-visibility: hidden;
        transform: translateZ(0);

        &>img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.app__delayed-block{
    .@{desktop} & {

        &_families{
            height: rem(68);
        }

        &_new-perfumes{
            height: rem(183);
        }

        &_main-today{
            height: rem(1580);
        }

        &_popular-perfumes{
            height: rem(339);
        }

        &_popular-sets{
            height: rem(304);
        }

        &_reviews{
            height: rem(681);
        }

        &_recommends{
            height: rem(524);
        }

        &_new-users{
            height: rem(309);
        }

        &_articles{
            height: rem(1554);
        }

        &_articles-big{
            height: rem(3085);
        }

        &_news-rubrics{
            height: rem(254);
        }

        &_articles-rubrics{
            height: rem(378);
        }

    }

    .@{mobile} & {

        &_families{
            height: rem(64);
        }

        &_new-perfumes{
            height: rem(161);
        }

        &_main-today{
            height: rem(3079);
        }

        &_popular-perfumes{
            height: rem(645);
        }

        &_popular-sets{
            height: rem(389);
        }

        &_reviews{
            height: rem(759);
        }

        &_recommends{
            height: rem(1014);
        }

        &_new-users{
            height: rem(306);
        }

        &_articles{
            height: rem(2895);
        }

        &_articles-big{
            height: rem(5936);
        }

        &_news-rubrics{
            height: rem(432);
        }

        &_articles-rubrics{
            height: rem(882);
        }

    }
}
</style>
