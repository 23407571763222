<i18n>
    {
    "ru": {
        "show_replies_start": "Показать",
        "replies_one": "ответ",
        "replies_few": "ответа",
        "replies_many": "ответов",
        "hide_replies_start": "Скрыть",
        "guest": "Гость",
        "reply": "Ответить",
        "follow": "Перейти",
        "show_user_comments": "Все отзывы пользователя",
        "hide_user_comments": "Скрыть отзывы пользователя",
        "at": "в",
        "he_left_a_review": "оставил отзыв",
        "she_left_a_review": "оставила отзыв",
        "is_brand_review": "о бренде",
        "go_to_post": "Перейти"
    }
}
</i18n>

<template>
    <section
        class="feed-post"
        :id="blockId">

        <BlockFeedPostHeader
            :no-gender="noGender"
            :name="name"
            :gender="gender"
            :photo="photo"
            :html-url="htmlUrl"
            :created-by="postData.createdBy"
            :brandName="brandName"
            :post-type="postType"
            :rating="rating"/>

        <template v-if="showLess">
            <!--eslint-disable-next-line-->
            <div v-html="$options.filters.textNoBr(text)" class="feed-post__content feed-post__content_less"></div>
            <div class="feed-post__date">{{ postDate }}</div>
        </template>

        <template v-else>
            <ShowMore
                class="feed-post__content"
                :expanded="expanded"
                :max-rows="maxRows"
                :collapse-from="scrollToBlock"
                :collapse-to="scrollToBlock">
                <!--eslint-disable-next-line-->
            <div v-html="text"></div>
            </ShowMore>

            <div class="feed-post__buttons">
                <RateButtons
                    class="feed-post__rate-buttons"
                    :comment-id="postData.id"
                    :likes="likes"
                    :dislikes="dislikes" />

                <button class="feed-post__reply-to"
                    v-if="canAnswer && !countReplies"
                    @click.prevent="replyTo">{{ $t('reply') }}</button>

                <nuxt-link
                    class="feed-post__go-to"
                    v-if="canAnswer && !countReplies"
                    to="/">{{ $t('go_to_post' )}}</nuxt-link>
            </div>

            <TransitionExpand>
                <div class="block-review__replies"
                    v-show="isShowReplies">

                    <BlockReview
                        class="block-review__reply"
                        :idPrefix="idPrefix"
                        v-for="reply in replies"
                        :key="reply.id"
                        :can-answer="false"
                        :comment="reply" />
                </div>
            </TransitionExpand>

            <footer class="feed-post__footer"
                v-if="countReplies">

                <transition
                    mode="out-in"
                    name="pop">

                    <button class="feed-post__button-show-replies"
                        v-if="countReplies"
                        :key="buttonShowText"
                        @click.prevent="triggerShowReplies">
                        {{ buttonShowText }}
                    </button>

                </transition>

                <button class="feed-post__reply-to"
                    v-if="canAnswer"
                    @click.prevent="replyTo">{{ $t('reply') }}</button>

                <nuxt-link
                    class="feed-post__go-to"
                    v-if="canAnswer"
                    to="/">{{ $t('go_to_post' )}}</nuxt-link>
            </footer>
        </template>
    </section>
</template>

<script>
// import { mapGetters } from 'vuex'
import get from 'lodash/get'
import orderBy from "lodash/orderBy";
import throttle from "lodash/throttle";
import dayjs from 'dayjs'
import RateButtons from '@/components/ui/RateButtons'
import BlockReview from '@/components/layout/blocks/BlockReview'
import BlockFeedPostHeader from '@/components/feed/blocks/BlockFeedPostHeader'

export default {
    name: 'BlockFeedBrandReview',
    components: {
        RateButtons,
        BlockFeedPostHeader,
        BlockReview
    },
    props: {
        noGender: {
            type: Boolean,
            default: false
        },
        postType: {
            type: String,
            default: null
        },
        postData: {
            type: Object,
            default: () => {}
        },
        showLess: {
            type: Boolean,
            default: false
        },
        expanded: {
            type: Boolean,
            default: false
        },
        maxRows: {
            type: Number,
            default: 4
        },
        canAnswer: {
            type: Boolean,
            default: true
        },
        idPrefix: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        rowsCount: 0,
        isShowReplies: false,
        replies: []
    }),
    computed: {
        isShowed() {
            return this.postData.text.length > 0
        },
        name() {
            return get(this.postData, 'createdBy.name', this.$t('guest'))
        },
        gender() {
            return get(this.postData, 'createdBy.gender')
        },
        htmlUrl() {
            return get(this.postData, 'brand.htmlUrl')
        },
        brandName() {
            return get(this.postData, 'brand.name', 'Chanel')
        },
        photo() {
            return get(this.postData, 'brand.logo')
        },
        rating() {
            return get(this.postData, 'stars')
        },
        text() {
            return get(this.postData, 'text')
        },
        likes() {
            return get(this.postData, 'ratings.likes', 0)
        },
        dislikes() {
            return get(this.postData, 'ratings.dislikes', 0)
        },
        blockId() {
            const date = new Date(get(this.postData, 'createdAt', ''))

            const dateStr = dayjs(date).format('DDMMYYYYhmm')

            return this.idPrefix + '_' + (this.postData.createdBy.name || this.postData.createdBy.id).replace(/\s+/g, '') + dateStr
        },
        scrollToBlock() {
            return '#' + this.blockId
        },
        scrollToReplies() {
            return '#' + this.repliesId
        },
        repliesId() {
            return this.idPrefix + '_' + 'replies' + this.blockId
        },
        repliesDeclension() {
            return [
                this.$t('replies_one'),
                this.$t('replies_few'),
                this.$t('replies_many')
            ]
        },
        buttonShowText() {
            const end = this.$options.filters.numberDeclension(this.countReplies, this.repliesDeclension)

            const startKey = this.isShowReplies ? 'hide_replies_start' : 'show_replies_start'
            const startStr = this.$t(startKey)

            return `${startStr} ${end}`
        },
        getReplies() {
            const replies = get(this.postData, 'replies', [])

            return orderBy(replies, reply => {
                return new Date(reply.createdAt)
            }, 'asc')
        },
        countReplies() {
            return this.replies.length
        },
        replyToEventName() {
            return 'reply-to-comment-' + this.blockId
        },
        postDate(){
            const translations = {
                lastday_in: this.$t('date.lastday_in'),
                today_in: this.$t('date.today_in'),
                in: this.$t('date.in'),
            }

            return this.$options.filters.datePost(this.postData.createdAt, translations)
        }
    },
    beforeDestroy() {
        if (this.$bus._events[this.replyToEventName])
            this.$bus._events[this.replyToEventName] = []

        if (this.$bus._events[this.commentToEventName])
            this.$bus._events[this.commentToEventName] = []
    },
    mounted() {
        this.replies = this.getReplies

        // eslint-disable-next-line
        if (process.client) {
            this.$bus.$on(this.replyToEventName, callback => {
                callback()
            })

            this.$bus.$on(this.commentToEventName, comment => {
                this.replies.push(comment)
                this.replies = this.getReplies
            })
        }
    },
    methods: {
        triggerShowReplies: throttle(function() {
            this.isShowReplies = !this.isShowReplies

            if (this.isShowReplies) {
                this.$scrollTo(this.scrollToReplies, 750, {
                    offset: -56
                })
            } else {
                this.$scrollTo(this.scrollToBlock, 750, {
                    offset: -56
                })
            }
        }),
        replyTo() {
            this.$authEmit(this.replyToEventName, () => {
                this.showPopup('popup-comment', {
                    replyTo: this.postData.id
                })
            })
        }
    }
}
</script>

<style lang="less">
.feed-post {
    --photo-width: rem(70);
    --photo-height: rem(40);

    border-top: rem(1) solid @color-gray-white;

    padding: rem(22) rem(20);

    transition-duration: .6s;
    transition-property: opacity;
    transition-timing-function: ease;

    // &:not(&__reply) + & {
    //     border-top: rem(1) solid @color-gray-white
    // }

    @{desktop} & {
        padding: rem(32) 0;
    }

    &__expert {
        margin-left: rem(5);

        height: rem(16);
        width: rem(16);

        position: absolute;
        top: rem(1)
    }

    &__reply {
        box-shadow: inset 2px 0px 0px #ECECEC;

        margin-top: rem(16);
        padding-top: rem(16);
        padding-bottom: rem(16)
    }

    &__pictures {
        display: flex;

        margin-top: rem(12);
        margin-bottom: rem(16);

        picture {
            display: block;
            cursor: pointer;

            img {
                object-fit: cover;

                border-radius: rem(3);

                height: rem(56);
                width: rem(56);
            }

            & + picture {
                margin-left: rem(12);
            }
        }
    }

    &__data {
        color: @color-black;

        &-name {
            font-size: rem(15);

            margin-bottom: 0;

            height: rem(22.5);
            max-width: rem(132);

            position: relative;

            @media @min375 {
                max-width: initial;
            }

            b {
                margin-right: rem(6);
            }
        }

        &-date {
            color: @color-gray;
            font-size: rem(13);
        }
    }

    &__help {
        transform: translateY(rem(-2));
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content {
        line-height: 155%;

        padding-top: rem(10);

        &_less {
            line-height: rem(23);
            word-break: break-word;

            @{mobile} & {
                padding-top: rem(12);

                // height: rem(100);
                .text-ellipsis(4);
            }

            @{desktop} & {
                padding-top: rem(12);

                // height: rem(80);
                .text-ellipsis(3);
            }
        }
    }

    &__content + &__buttons {
        margin-top: rem(14);
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: rem(16) 0 0;
    }

    &__button-show-replies {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background: @color-gray-light-2;
        border: none;
        border-radius: rem(3);
        color: @color-black;
        font-size: rem(13);

        padding: rem(6) rem(10);

        @media @min375 {
            padding: rem(6) rem(16);
        }
    }

    &__reply-to,
    &__go-to {
        background: none;
        border: none;
        color: @color-gray-light;

        margin-left: auto;
        padding: 0;
    }

    &__go-to {
        font-size: rem(14);

        margin-left: rem(8);

        @media @min375 {
            font-size: rem(15);

            margin-left: rem(20);
        }
    }

    &__show-user-comments {
        margin-bottom: rem(10);
    }
}
</style>
