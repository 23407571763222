<template>
    <button
        :disabled="disabled"
        class="button-cebab"
        :class="`button-cebab_${classModificator}`">
        <span class="button-cebab__dot"></span>
        <span class="button-cebab__dot"></span>
        <span class="button-cebab__dot"></span>
    </button>
</template>

<script>
export default {
    name: 'ButtonCebab',
    props: {
        classModificator: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="less">
.button-cebab {
    --size: rem(22);
    display: flex;
    align-items: center;
    justify-content: space-around;

    background-color: transparent;
    border: none;

    margin: 0;
    padding: 0;

    height: var(--size);
    width: var(--size);

    &__dot {
        display: block;

        background-color: @color-gray-light;
        border-radius: 50%;

        height: rem(4);
        width: rem(4);
    }

    &_dark {
        --size: rem(24);

        .button-cebab__dot {
            background-color: @color-black;
        }
    }

    .animate(background-color);

    .hover({
        .button-cebab__dot {
            background-color: @color-black;
        }
    })
}
</style>
