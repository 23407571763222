<i18n>
{
    "ru": {
        "at": "в"
    }
}
</i18n>
<template>
    <div
        class="reviews-slider">

        <PageSlider
            class="reviews-slider__swiper"
            :sections="sections">

            <template #item="{ section }">
                <div class="reviews-slider__slide">
                    <component
                        v-for="(feed) in section"
                        :key="feed.id"
                        :is="getComponentName(feed)"
                        v-bind="getPostBindings(feed)"/>
                </div>
            </template>
        </PageSlider>
    </div>
</template>

<script>
import chunk from 'lodash/chunk'
import get from 'lodash/get'

import PageSlider from '@/components/layout/blocks/PageSlider'

import BlockFeedBrandReview from '@/components/feed/blocks/BlockFeedBrandReview';
import BlockFeedPerfume from '@/components/feed/blocks/BlockFeedPerfume';
import BlockFeedShopReview from '@/components/feed/blocks/BlockFeedShopReview';
import BlockFeedUserComment from '@/components/feed/blocks/BlockFeedUserComment';

export default {
    name: 'ReviewsSlider',
    components: {
        PageSlider,
        BlockFeedUserComment,
        BlockFeedBrandReview,
        BlockFeedPerfume,
        BlockFeedShopReview
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        sections() {
            return chunk(this.items, 3)
        }
    },
    methods: {
        get,
        chunk,
        getComponentName(feed) {
            let componentName = 'BlockFeedUserComment'

            if (feed.brand) {
                componentName = 'BlockFeedBrandReview'
            }
            if (feed.perfume) {
                componentName = 'BlockFeedPerfume'
            }
            if (feed.shop) {
                componentName = 'BlockFeedShopReview'
            }

            return componentName
        },
        getPostBindings(feed) {
            return {
                postData: feed,
                postType: this.getPostType(feed),
                noGender: true,
                showLess: true
            }
        },
        getPostType(feed) {
            let type = 'user_comment'

            if (feed.perfume) {
                type = 'perfume_review'
            } else if (feed.brand) {
                type = 'brand_review'
            } else if (feed.shop) {
                type = 'shop_review'
            }

            return type
        },
    }


}
</script>

<style lang="less">
.reviews-slider {
    // padding-bottom: rem(7);

    .page-slider__button {
        // transform: rotateZ(90deg);
    }

    &__item {
        border-bottom: rem(1) solid @color-gray-white;

        padding-top: 0;
        padding-bottom: 0;
    }

    .block-review {

        &__pictures {
            display: none;
        }
    }

    .feed-post {
        &:first-of-type {
            border-top: none;

            @{mobile} & {
                padding-top: rem(16);
            }
        }

        @{desktop} & {
            padding-top: rem(15);
            padding-bottom: rem(20);
        }

        &-header {

            &__actions {
                display: none;
            }
        }

        &-article {

            &__footer {
                display: none;
            }
        }

        &__buttons,
        &__footer {
            display: none;
        }
    }
}

</style>
