<!-- Используется на главной в блоке новые пользователи, семейства -->
<template>
    <html-link
        :url="htmlUrl"
        class="rounded-block"
        :class="{'rounded-block_user': type === 'user'}">
        <img v-if="imageUrl" 
            v-lazy-load :data-src="imageUrl"
            :alt="title"
            class="rounded-block__image" />
        <svg-icon v-else
            class="rounded-block__icon"
            :name="'placeholder-profile'" />
        <span class="rounded-block__title">{{ title }}</span>
    </html-link>
</template>

<script>

export default {
    name: 'BlockBorderedWithImage',
    props: {
        title: {
            type: String,
            default: ''
        },
        imageUrl: {
            type: String,
            default: null
        },
        htmlUrl: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'user'
        }
    }
}
</script>

<style lang="less">
.rounded-block{
    display: flex;
    align-items: center;
    padding: rem(11) rem(16) rem(11) rem(11);
    border: rem(1) solid @color-gray-white;
    border-radius: rem(100);
    color: @color-black;
    font-size: rem(13);
    line-height: rem(15);
    font-weight: 500;
    transition: border-color .15s ease;

    &_user{
        .@{mobile} & {
            padding: rem(7) 0;
            // font-size: rem(11);
            // line-height: rem(18);
            border: none;

            // .rounded-block{
            //     &__image,
            //     &__icon{
            //         width: rem(16);
            //         height: rem(16);
            //         flex: 0 0 rem(16);
            //         margin-right: rem(8);
            //     }
            // }
        }
    }

    &__image{
        width: rem(20);
        height: rem(20);
        object-fit: cover;
        border-radius: 100%;
        margin-right: rem(12);
        flex: 0 0 rem(20);
        background-color: @color-gray-light;
    }

    &__icon{
        height: rem(20);
        width: rem(20);
        margin-right: rem(12);
    }

    &__title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: rem(1);
    }

    .hover({
        color: @color-black;
        border-color: @color-primary;
    })
}
</style>