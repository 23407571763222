<i18n>
    {
        "en": {
            "title_actions": "Actions",
            "title_settings": "Settings"
        },
        "ru": {
            "title_actions": "Действия",
            "title_settings": "Настройки"
        }
    }
</i18n>

<template>

    <modal-content
        :noPaddingBody="true"
        :class="rootClasses">
        <template v-if="!isTooltip" #title>
            {{ getTitle(titleType) }}
        </template>

        <template #body>
            <div class="popup-actions">
                <div class="popup-actions__container">
                    <button
                        :key="link.name"
                        :class="link.customClass ? link.customClass : ''"
                        class="popup-actions__link"
                        :disabled="link.disabled || false"
                        @click="handleLinkClick(link)"
                        v-for="link in modalItems" >
                        {{link.name}}
                    </button>
                </div>
            </div>
        </template>
    </modal-content>

</template>

<script>
import ModalContent from '@/components/modal/ModalContent'

export default {
    name: 'Actions',
    components: {
        ModalContent,
    },
    props: {
        titleType: {
            type: String,
            default: 'actions'
        },
        modalItems: {
            type: Array,
            default: () => []
        },
        isTooltip: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        }
    },
    computed: {
        rootClasses() {
            return {
                'popup-actions-modal_tooltip': this.isTooltip
            }
        }
    },
    beforeDestroy(){
        this.$bus.$emit('on-actions-modal-close');
    },
    methods: {
        handleLinkClick(item) {
            if(item.disabled) return;

            if(item.eventName) {
                if(item.data) {
                    item.withAuth ? this.$authEmit(item.eventName, item.data)  : this.$bus.$emit(item.eventName, item.data);
                }
                else {
                    item.withAuth ? this.$authEmit(item.eventName)  : this.$bus.$emit(item.eventName);
                }
            }
            if(item.modal) {
                this.closePopup()
                this.showPopup(item.modal, item.data)
            }
            if(item.linkUrl) {
                this.closePopup()
                this.$router.push(item.linkUrl).catch(error => {
                    console.info(error.message)
                })
            }
            if(item.linkUrl && item.id) {
                this.closePopup()
                this.$router.push(item.linkUrl + item.id)
            }
        },
        getTitle(title) {
            if(this.title) return this.title;
            
            if (title === 'actions') {
                return this.$t('title_actions')
            } else if(title === 'settings') {
                return this.$t('title_settings')
            }
        }
    }
}
</script>

<style lang="less">
.popup-actions-modal {
    &_tooltip{
        background-color: @color-white;
        border-radius: rem(3);
        box-shadow: 0 rem(3) rem(16) rgba(0, 0, 0, 0.08);
        height: auto;

        &.modal-content{
            height: auto;
        }

        position: absolute;
        top: 100%;
        right: 0;
        z-index: 100;

        overflow: hidden;
        @{desktop} & {
            min-width: rem(250);
        }
    }
}


.popup-actions {
    padding-bottom: rem(12);

    &__container {
        display: flex;
        flex-direction: column;

        line-height: 1.75;
    }

    &__link {
        display: block;
        cursor: pointer;

        background: none;
        border: none;
        border-bottom: 1px solid @color-gray-white;
        font-size: rem(16);
        height: rem(46);
        outline: none;
        text-align: left;
        white-space: nowrap;

        padding: rem(11) rem(20) rem(12);

        &:last-of-type {
            border-bottom: 1px solid transparent;
        }

        &_danger {
            color: @color-red;
        }

        .hover({
            background: @color-gray-light-2;
        });

        &[disabled],
        &_disabled {
            color: @color-gray-light;

            &:hover {
                cursor: default;

                background: none;
            }
        }
    }

    @{desktop} & {
        padding-bottom: 0;
    }
}
</style>
