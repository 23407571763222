<i18n>
    {
    "ru": {
        "he_responded": "Ответил",
        "she_responded": "Ответила",
        "responded_comment": "{genderVerb} на комментарий пользователя",
        "responded_review": "{genderVerb} на отзыв пользователя",

        "he_left_a_review": "оставил отзыв",
        "she_left_a_review": "оставила отзыв",
        "about_brand": "о бренде",
        "about_perfume": "об аромате",
        "about_shop": "о магазине",
        "add_new_aroma": "Добавлен новый аромат",
        "add_new_note": "Добавлена новая нота",
        "at_brand": "от бренда",
        "new_from_shop": "Опубликована новость от магазина",
        "article_from_tag": "Опубликована статья с тегом",
        "new_from_tag": "Опубликована новость с тегом",
        "with_note": "с нотой",
        "at_notes": "в группе нот",
        "at_groups": "в ольфакторную группу",
        "from_perfumer": "от парфюмера",
        "she_added_post": "Добавила новую запись",
        "he_added_post": "Добавил новую запись",
        "she_added_article": "Опубликовала статью",
        "he_added_article": "Опубликовал статью",
        "she_added_new": "Опубликовала новость",
        "he_added_new": "Опубликовал новость",
        "she_review_perfume": "Оставила отзыв об аромате",
        "he_review_perfume": "Оставил отзыв об аромате",
        "she_review_brand": "Оставила отзыв о бренде",
        "he_review_brand": "Оставил отзыв о бренде",
        "she_review_shop": "Оставила отзыв о магазине",
        "he_review_shop": "Оставил отзыв о магазине",

        "comment_article": "к статье",
        "comment_new": "к новости",
        "comment_post": "записи",
        "comment_perfume": "об аромате",

        "she_comment_article": "Оставила комментарий к статье",
        "he_comment_article": "Оставил комментарий к статье",
        "she_comment_new": "Оставила комментарий к новости",
        "he_comment_new": "Оставил комментарий к новости",

        "she_comment_post": "Оставила комментарий к записи",
        "he_comment_post": "Оставил комментарий к записи",

        "she_comment_perfume": "Оставила комментарий к аромату",
        "he_comment_perfume": "Оставил комментарий к аромату",

        "she_comment_brand": "Оставила комментарий к бренду",
        "he_comment_brand": "Оставил комментарий к бренду",

        "she_comment_shop": "Оставила комментарий к магазину",
        "he_comment_shop": "Оставил комментарий к магазину",

        "to": "к",

        "unsubscribe": "Отписаться",
        "undefinedContent": "Оставил комментарий",

        "in": "в",
        "today_in": "Сегодня в",
        "lastday_in": "Вчера в"
    }
}
</i18n>
<template>
    <header class="feed-post-header" :class="customClass">
        <picture
            class="feed-post-header__photo"
            :class="customClass">
            <img
                v-lazy-load :data-src="!userDeleted ? photoUrl : getUserNoImageLarge"
                :alt="photoAlt"/>
        </picture>

        <div
            class="feed-post-header__info"
            :class="customClass">

            <!-- TODO разобрать эти условия-->

            <div v-if="isEqual(headerType, 'rating')"
                class="feed-post-header__name">

                <template v-if="name">
                    <nuxt-link
                        v-if="isShowLink"
                        class="feed-post-header__user-name"
                        :to="{name: 'users.detail', params: {userId: createdBy.id}}">
                        {{ name }}
                    </nuxt-link>

                    <span v-else class="feed-post-header__user-name"> {{name}} </span>
                </template>

                <template v-if="!noGender">
                    <span v-if="isEqual(gender, 'female')">
                        {{ $t('she_left_a_review') }}
                    </span>

                    <span v-else>
                        {{ $t('he_left_a_review') }}
                    </span>
                </template>

                <span>
                    {{ getRatingHeader }}
                </span>

                <html-link
                    class="feed-post-header__link"
                    :url="postLink(generateUrl)">
                    {{ `${headerTypeName}`}}
                </html-link>
            </div>

            <div v-if="isEqual(headerType, 'article')">
                <div v-if="isEqual(postType, 'shop_article')">
                    <p class="feed-post-header__tag-title">
                        {{ $t('new_from_shop') }}
                    </p>

                    <html-link
                        v-if="generateUrl"
                        class="feed-post-header__link feed-post-header__tag"
                        :url="generateUrl">
                        {{ `${shopName}`}}
                    </html-link>
                </div>

                <div v-if="isEqual(postType, 'tag_new')">
                    <p class="feed-post-header__tag-title">
                        {{ $t('new_from_tag') }}
                    </p>

                    <html-link
                        v-for="(tag, index) in tags"
                        :key="`feed-tag-post-${index}`"
                        class="feed-post-header__link feed-post-header__tag"
                        :url="tag.htmlUrl">
                        {{ tag.name }}
                    </html-link>
                </div>

                <div v-if="isEqual(postType, 'tag_article')">
                    <p class="feed-post-header__tag-title">
                        {{ $t('article_from_tag') }}
                    </p>

                    <html-link
                        v-for="(tag, index) in tags"
                        :key="`feed-tag-post-${index}`"
                        class="feed-post-header__link feed-post-header__tag"
                        :url="tag.htmlUrl">
                        {{ tag.name }}
                    </html-link>
                </div>

            </div>

            <div v-if="isEqual(headerType, 'newAroma')"
                class="feed-post-header__name">
                <!-- TODO переименовать пропсы ссылок-->

                <span v-if="isEqual(postType, 'notes')">{{ $t('add_new_note') }}</span>
                <span v-else>{{ $t('add_new_aroma') }}</span>

                <router-link
                    class="feed-post-header__link"
                    to="/">
                    {{ `${perfumeName}`}}
                </router-link>

                <span v-if="isEqual(postType, 'brand_new_perfume')">{{ $t('at_brand') }}</span>
                <span v-if="isEqual(postType, 'note')">{{ $t('with_note') }}</span>
                <span v-if="isEqual(postType, 'notes')">{{ $t('at_notes') }}</span>
                <span v-if="isEqual(postType, 'groups')">{{ $t('at_groups') }}</span>
                <span v-if="isEqual(postType, 'person')">{{ $t('from_perfumer') }}</span>

                <router-link
                    class="feed-post-header__link"
                    to="/">
                    {{ `${brandName}`}}
                </router-link>
            </div>

            <div class="feed-post-header__user" v-else-if="isUserHeader">
                <div class="feed-post-header__title">
                    <template v-if="name">
                        <nuxt-link
                            v-if="isShowLink"
                            class="feed-post-header__user-name"
                            :to="{name: 'users.detail', params: {userId: createdBy.id}}">
                            {{ name }}
                        </nuxt-link>

                        <span v-else class="feed-post-header__user-name"> {{name}} </span>
                    </template>

                    <div v-if="isShowDate" class="feed-post-header__date">
                        {{ formatedDate }}
                    </div>
                </div>
                <client-only>
                    <p class="feed-post-header__subtitle">
                        <span v-if="isEqual(postType, 'user_post') && noGender">
                            {{ $t('to') }}
                            <nuxt-link
                                v-if="get(parent, 'createdBy.fullName')"
                                :to="profileLink"
                                class="feed-post-header__subtitle feed-post-header__subtitle_parent">
                                {{ userPostTitle + ' ' + get(parent, 'createdBy.fullName') }}
                            </nuxt-link>

                            <html-link
                                v-else
                                :url="postLink(authorProfile)"
                                class="feed-post-header__subtitle">
                                {{ userPostTitle + ' ' + authorPost }}
                            </html-link>
                        </span>
                        <span v-else>
                            <template v-if="get(parent, 'createdBy.fullName')">
                                {{ computedParentHeaderSubtitle }}
                                <nuxt-link
                                    v-if="get(parent, 'createdBy.fullName')"
                                    :to="profileLink"
                                    class="feed-post-header__subtitle feed-post-header__subtitle_parent">
                                    {{ get(parent, 'createdBy.fullName') }}
                                </nuxt-link>
                            </template>
                            <template v-else>
                                {{ userPostTitle }}
                                <html-link
                                    class="feed-post-header__post-author-link"
                                    :url="postLink(authorProfile)"
                                    v-if="showPostAuthorName">
                                    {{ authorPost }}
                                </html-link>
                            </template>
                        </span>
                    </p>
                </client-only>
                <!--eslint-disable-next-line-->
                <html-link v-html="authorPost"
                    v-if="!(isEqual(postType, 'user_post') || commentType === 'post')"
                    class="feed-post-header__link feed-post-header__link_small"
                    :url="postLink(authorProfile)" />
            </div>

            <div class="feed-post-header__rating rating"
                v-if="!get(parent, 'createdBy', false) && rating > 0 && !ratingWithName"
                :data-value="parseInt(rating)"></div>
        </div>

        <div
            @mouseover="showActions = true"
            @mouseleave="showActions = false"
            :data-show="showActionsButton"
            v-if="showActionsDesktop"
            class="feed-post-header__actions">

            <ButtonCebab @click.native="!isDeviceDesktop ? onMoreClick() : undefined"/>
            <transition name="fade">
                <Actions
                    is-tooltip
                    v-if="showActions"
                    :modalItems="getModalItems"/>
            </transition>

        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import ButtonCebab from '@/components/ui/buttons/ButtonCebab'
import Actions from "@/components/modal/ui/Actions";
export default {
    name: 'BlockFeedPostHeader',
    components: {
        Actions,
        ButtonCebab
    },
    props: {
        postType: {
            type: String,
            default: null
        },
        reviewType: {
            type: String,
            default: null
        },
        commentType: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        gender: {
            type: String,
            default: null
        },
        brandName: {
            type: String,
            default: null
        },
        perfumeName: {
            type: String,
            default: null
        },
        createdBy: {
            type: Object,
            default: null
        },
        shopName: {
            type: String,
            default: null
        },
        authorPost: {
            type: String,
            default: null
        },
        authorProfile: {
            type: String,
            default: null
        },
        photo: {
            type: Object,
            default: () => {}
        },
        rating: {
            type: Number,
            default: 0
        },
        noGender: {
            type: Boolean,
            default: false
        },
        noPhoto: {
            type: Boolean,
            default: false
        },
        newPerfume: {
            type: Object,
            default: () => {}
        },
        tags: {
            type: Array,
            default: () => []
        },
        htmlUrl: {
            type: String,
            default: null
        },
        showActionsButton: {
            type: Boolean,
            default: true
        },
        userDeleted: {
            type: Boolean,
            default: false
        },
        showPostAuthorName: {
            type: Boolean,
            default: false
        },
        parent: {
            type: Object,
            default: null
        },
        postId: {
            type: Number,
            default: null
        },
        currentUser: {
            type: Boolean,
            default: false
        },
        postDate: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showActions: false,
            showActionsDesktop: true
        }
    },
    computed: {
        ...mapGetters(['getEntityNoImage', 'isDeviceDesktop', 'getUserNoImageLarge']),
        computedParentHeaderSubtitle() {
            const translateStringName = this.parent.stars ? 'responded_review' : 'responded_comment';
            const genderVerb = this.gender === 'female' ? this.$t('she_responded') : this.$t('he_responded');

            return this.$t(translateStringName, {
                genderVerb
            })
        },
        isShowLink() {
            if(this.currentUser){
                return false
            }else{
                return !!(this.createdBy && this.createdBy.id)
            }
        },
        generateUrl() {
            return this.htmlUrl
        },
        isUserHeader() {
            return isEqual(this.headerType, 'user')
        },
        userPostTitle() {
            const { postType, isWoman } = this
            const gender = isWoman

            let userPostTitle = ''

            if(postType){

                switch(postType) {
                case 'user_post':
                    if (this.noGender) {
                        userPostTitle = this.$t('comment_post')
                    } else if(gender) {
                        userPostTitle = this.$t('she_added_post')
                    } else {
                        userPostTitle = this.$t('he_added_post')
                    }
                    break
                case 'user_article':
                    if(gender) {
                        userPostTitle = this.$t('she_added_article')
                    } else {
                        userPostTitle = this.$t('he_added_article')
                    }
                    break
                case 'user_new':
                    if(gender) {
                        userPostTitle = this.$t('she_added_new')
                    } else {
                        userPostTitle = this.$t('he_added_new')
                    }
                    break
                case 'user_review':
                    if(gender) {
                        if(this.reviewType === 'perfume') {
                            userPostTitle = this.$t('she_review_perfume')
                        } else if(this.reviewType === 'brand') {
                            userPostTitle = this.$t('she_review_brand')
                        } else if(this.reviewType === 'shop') {
                            userPostTitle = this.$t('she_review_shop')
                        }
                    } else if(!gender) {
                        if(this.reviewType === 'perfume') {
                            userPostTitle = this.$t('he_review_perfume')
                        } else if(this.reviewType === 'brand') {
                            userPostTitle = this.$t('he_review_brand')
                        } else if(this.reviewType === 'shop') {
                            userPostTitle = this.$t('he_review_shop')
                        }
                    }
                    break
                case 'user_comment':
                    if (this.noGender) {
                        if(this.commentType === 'new') {
                            userPostTitle = this.$t('comment_new')
                        } else if(this.commentType === 'article') {
                            userPostTitle = this.$t('comment_article')
                        } else if(this.commentType === 'post') {
                            userPostTitle = this.$t('comment_post')
                        } else if(this.commentType === 'perfume') {
                            userPostTitle = this.$t('comment_perfume')
                        } else if(this.commentType === 'brand') {
                            userPostTitle = this.$t('comment_brand')
                        }
                    } else if(gender) {
                        if(this.commentType === 'new') {
                            userPostTitle = this.$t('she_comment_new')
                        } else if(this.commentType === 'article') {
                            userPostTitle = this.$t('she_comment_article')
                        } else if(this.commentType === 'post') {
                            userPostTitle = this.$t('she_comment_post')
                        } else if(this.commentType === 'perfume') {
                            userPostTitle = this.$t('she_comment_perfume')
                        } else if(this.commentType === 'brand') {
                            userPostTitle = this.$t('she_comment_brand')
                        } else if(this.commentType === 'shop') {
                            userPostTitle = this.$t('she_comment_shop')
                        }
                    } else if(!gender) {
                        if(this.commentType === 'new') {
                            userPostTitle = this.$t('he_comment_new')
                        } else if(this.commentType === 'article') {
                            userPostTitle = this.$t('he_comment_article')
                        } else if(this.commentType === 'post') {
                            userPostTitle = this.$t('he_comment_post')
                        } else if(this.commentType === 'perfume') {
                            userPostTitle = this.$t('he_comment_perfume')
                        } else if(this.commentType === 'brand') {
                            userPostTitle = this.$t('he_comment_brand')
                        } else if(this.commentType === 'shop') {
                            userPostTitle = this.$t('he_comment_shop')
                        }
                    }
                    break
                }
            }else{
                userPostTitle = this.$t('undefinedContent')
            }
            return userPostTitle
        },
        getRatingHeader() {
            if (this.brandName) return this.$t('about_brand')
            if (this.perfumeName) return this.$t('about_perfume')
            if (this.shopName) return this.$t('about_shop')

            return ''
        },
        getModalItems() {
            return [
                {
                    name: this.$t('unsubscribe'),
                    customClass:'popup-actions__link_danger',
                }
            ]
        },
        photoUrl() {
            return this.postType === 'user_comment' ? 
                get(this.photo, 'url', this.getUserNoImageLarge) :
                get(this.photo, 'url', this.getEntityNoImage(this.postType))
        },
        photoAlt() {
            return get(this.photo, 'alt', '')
        },
        headerType() {
            let headerType = 'user_post'
            if(this.postType === 'brand_review' || this.postType === 'perfume_review' || this.postType === 'shop_review') {
                headerType = 'rating'
            } else if(this.postType === 'shop_article' || this.postType === 'tag_article' || this.postType === 'tag_new') {
                headerType = 'article'
            } else if(this.postType === 'brand_new_perfume' || this.postType === 'note' || this.postType === 'notes' || this.postType === 'groups' || this.postType === 'person') {
                headerType = 'newAroma'
            } else if(this.postType === 'user_post' || this.postType === 'user_article' || this.postType === 'user_new' || this.postType === 'user_review' || this.postType === 'user_comment' || !this.postType) {
                headerType = 'user'
            }

            return headerType
        },
        headerTypeName() {
            let typeName = ''
            switch(this.postType) {
            case 'brand_review':
                typeName = this.brandName
                break
            case 'perfume_review':
                typeName = this.perfumeName
                break
            case 'shop_review':
                typeName = this.shopName
                break
            }
            return typeName
        },
        customClass() {
            let customClass = ''
            if(this.noPhoto) {
                customClass = 'feed-post-header_full-width'
            } else if (this.headerType === 'newAroma' || this.headerType === 'user') {
                customClass = 'feed-post-header_new-aroma'
            }

            if (this.noGender) {
                customClass += ' feed-post-header_no-gender'
            }

            if (this.headerType === 'rating')
                customClass += ' feed-post-header_square'

            if(this.isShowDate)
                customClass += ' feed-post-header_date'

            return customClass
        },
        isWoman() {
            return this.gender === 'female'
        },
        ratingWithName() {
            return this.postType === 'user_review'
        },
        isShowDate(){
            // показываем дату только в профиле у отзывов и комментариев
            if(!this.postType) return true
            return !!(this.postDate && this.$route.name === 'users.detail' && (this.postType === 'user_review' || this.postType === 'user_comment'))
        },
        translations(){
            return {
                today_in: this.$t('today_in'),
                lastday_in: this.$t('lastday_in'),
                in: this.$t('in')
            }
        },
        formatedDate() {
            return this.$options.filters.datePost(this.postDate, this.translations, this.$i18n.locale, 'DD.MM.YYYY')
        },
        profileLink(){
            return {
                name: 'users.detail',
                params: {
                    userId: this.parent.createdBy.id
                }
            };
        }
    },
    methods: {
        get,
        isEqual,
        onMoreClick() {
            const modalItems = [
                {
                    name: 'Отписаться',
                    customClass: 'popup-actions__link_danger',
                    modal: 'unsubscribe',
                    data: {
                        title: 'Отписаться',
                        postType: this.postType
                    }
                }
            ];
            this.showPopup('actions', {
                modalItems,
            });
        },
        postLink(link){
            if(this.postId)
                return link + '#comment_' + this.postId

            return link
        }
    }
}
</script>

<style lang="less">
.feed-post-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__post-author-link {
        color: @color-black;
        text-decoration: underline;

        .hover({
            text-decoration: none;
        })
    }

    &__photo-deleted {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: @color-gray-light-2;

        width: 100%;

        &_icon {
            height: rem(20);
            width: rem(20);
        }
    }

    &_no-gender &__user {
        padding-bottom: rem(6);

        div, p {
            display: inline-block;

            color: @color-gray;
            font-size: rem(13);
            font-weight: 400;
        }

        &-name {

            &[href] {

                @{mobile} &,
                @{desktop} & {
                    text-decoration: underline;
                }
            }
        }
    }

    &_square{
        .feed-post-header__photo{
            &>img{
                border-radius: 0;
                object-fit: contain;
            }
        }
    }

    &_no-gender &__photo {
        margin-top: rem(5);
    }

    &_date{
        .feed-post-header{

            &__title{

                @{desktop} & {
                    display: flex;
                    align-items: center;
                }

                @{mobile} & {
                    margin-bottom: rem(4);
                }

            }

            &__actions{
                display: none;
            }

            &__info{
                padding-right: 0;
            }

            &__user-name{
                font-weight: 500;
            }
        }

        @{mobile} & {
            &.feed-post-header{
                margin-bottom: rem(6);
            }
        }
    }

    &__title {
        font-size: rem(15);
        margin-bottom: rem(3);

        @{mobile} & {
            margin-top: rem(3);
            margin-bottom: rem(5);
        }
    }

    &__date{
        font-size: rem(13);
        line-height: rem(20);
        color: @color-gray;
        margin-left: auto;

        @{mobile} & {
            margin-top: rem(2);
        }
    }

    &__user-name {
        color: @color-black;
        display: inline-block;

        &[href] {
            text-decoration: none;

            .hover({
                color: @color-primary;
            });
        }
    }

    &__subtitle {
        color: @color-gray;
        font-size: rem(13);

        margin-bottom: 0;

        &[href] {
            display: inline-block;

            text-decoration: underline;

            margin-bottom: rem(20);

            @{desktop} & {
                color: @color-black;

                margin-bottom: rem(22.5);

                .hover({
                    color: @color-primary;
                });
            }
        }

        &_parent {
            &[href] {
                margin-bottom: 0;

                @{desktop} & {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__photo {
        display: inline-flex;
        flex-shrink: 0;

        border-radius: 0;

        margin-right: rem(12);

        height: var(--photo-height);
        width: var(--photo-width);

        overflow: hidden;

        img {
            display: block;
            object-fit: cover;
            border-radius: 50%;

            height: 100%;
            width: 100%;
        }
    }

    &__info {
        padding-right: @dim-page-padding;

        @{mobile} &,
        @{desktop} & {
            width: calc(100% - var(--photo-width) - rem(14));
        }
    }

    &__name {
        color: @color-gray;
        font-size: rem(13);
        line-height: 1.6;

        padding-top: rem(3);

        max-width: 100%;

        position: relative;
        z-index: 3;
    }

    &__link {
        color: @color-black;
        text-decoration: underline;
        display: inline-block;

        .hover({
            color: @color-primary;
        });

        &_small {
            display: block;

            font-size: rem(13);

            max-width: 100%;

            @{desktop} & {
                text-overflow: ellipsis;
                white-space: nowrap;

                overflow: hidden;
            }
        }
    }

    &__rating {
        --star-width: rem(18);
        --star-height: rem(14);
        height: rem(14);
        transform: translate(rem(-1), rem(2));
    }

    &__tag-title {
        color: @color-gray;
        font-size: rem(13);
        line-height: 1.5;

        margin-bottom: rem(4);
    }


    &__tag {
        display: inline-flex;
        align-items: center;

        border: rem(1) solid @color-gray-white;
        border-radius: rem(3);
        color: @color-black;
        font-size: rem(11);
        text-align: center;
        text-decoration: none;
        white-space: nowrap;

        margin-right: rem(4);
        margin-bottom: rem(4);
        padding: rem(4) rem(8);

        position: relative;
    }

    &__footer {
        display: flex;

        width: 100%;
    }


    &_full-width {
        .feed-post-header__info {
            width: calc(100% - rem(38));
        }
    }

    &_new-aroma {
        .feed-post-header__info {
            // width: rem(255);
            width: calc(100% - var(--photo-width) - rem(38));
        }

        .feed-post-header__photo {
            border-radius: 50%;
        }
    }

    &__actions {
        position: relative;

        visibility: hidden;

        &[data-show] {
            visibility: visible;
        }
    }
}
</style>
