<template>
    <div class="block-persons-row">
        <div v-if="swiper"
            class="block-persons-row__swiper-slider"
            :class="sliderClasses">

            <swiper ref="swiper"
                @ready="onSwiperInit"
                :options="swiperOptions">

                <swiper-slide :key="item.code || item.id" v-for="item in items">
                    <slot name="item"
                        :item="item">

                        {{ get(item, 'title') }}

                    </slot>
                </swiper-slide>
            </swiper>
            <button
                v-if="canNavigation"
                slot="button-prev"
                :id="prevId"
                aria-label="Previous slide"
                class="swiper-button-prev block-persons-row__button block-persons-row__button_prev">

                <svg-icon name="chevron-left-current" />

            </button>

            <button
                v-if="canNavigation"
                slot="button-next"
                :id="nextId"
                aria-label="Next slide"
                class="swiper-button-next block-persons-row__button block-persons-row__button_next">

                <svg-icon name="chevron-right-current" />

            </button>
        </div>

        <BaseScrollSlider v-else class="block-persons-row__slider">
            <transition-group class="block-persons__transition-wrapper"
                tag="div"
                name="list-complete">
                <div class="scroll-slider__slide block-persons-row__slide list-complete-item"
                    v-for="item in items"
                    :key="item.code || item.id">
                    <slot name="item"
                        :item="item">

                        {{ get(item, 'title') }}

                    </slot>
                </div>
            </transition-group>
        </BaseScrollSlider>
    </div>
</template>

<script>
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import BaseScrollSlider from '@/components/ui/BaseScrollSlider';

export default {
    name: 'BlockPersonsRow',
    components: {BaseScrollSlider},
    props: {
        loop: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => [],
            isShowSlider: false
        },
        actionButtons: {
            type: Boolean,
            default: () => false
        },
        swiper: {
            type: Boolean,
            default: false
        },
        spaceBetween: {
            type: Number,
            default: 16
        },
        slidesPerView: {
            type: [ Number, String ],
            default: 'auto'
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        detectEdges: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loaded: false,
            isBeginning: true,
            isEnd: false
        }
    },
    computed: {
        nextId() {
            return 'next' + uniqueId()
        },
        prevId() {
            return 'prev' + uniqueId()
        },
        slidesPerGroup() {
            if (this.slidesPerView === 'auto') return 1

            if (this.slidesPerView <= 4) return 2

            const count = parseInt(this.slidesPerView / 3)
            return count > 1 ? count : 1
        },
        canNavigation() {
            if(this.slidesPerView === 'auto')
                return true
            return this.slidesPerView < this.items.length
        },
        swiperOptions() {
            const options = {
                slidesPerView: this.slidesPerView,
                slidesPerGroup: this.slidesPerGroup,
                spaceBetween: this.spaceBetween,
                loop: this.loop,
                loopedSlides: 20,
                passiveListeners: false
            }

            if (this.showNavigation && this.canNavigation) {
                options.navigation = {
                    nextEl: '#' + this.nextId,
                    prevEl: '#' + this.prevId
                }
            }

            return options
        },
        sliderClasses() {
            return {
                'block-persons-row__swiper-slider_loaded': this.loaded,
                'block-persons-row__swiper-slider_start': this.isBeginning,
                'block-persons-row__swiper-slider_end': this.isEnd
            }
        }
    },
    mounted(){
        if(this.$refs.swiper && this.detectEdges){
            this.$refs.swiper.$swiper.on('slideChange', () => {
                this.isBeginning = this.$refs.swiper.$swiper.isBeginning
                this.isEnd = this.$refs.swiper.$swiper.isEnd
            })

            this.$refs.swiper.$swiper.on('reachEnd', () => {
                this.isEnd = this.$refs.swiper.$swiper.isEnd
            })
        }
    },
    methods: {
        get,
        onSwiperInit() {
            this.isShowSlider = true
            this.loaded = true
        }
    }
};
</script>

<style scoped>
.list-complete-item {
    transition: all .4s ease;
}
.list-complete-enter, .list-complete-leave-to
    /* .list-complete-leave-active до версии 2.1.8 */ {
    opacity: 0;

    transform: translateX(50vw)
}
.list-complete-leave-active {
    position: absolute;
}
</style>

<style lang="less">
.block-persons {

    &__transition-wrapper {
        display: flex;
    }

    &-row {
        padding: rem(5) 0 rem(2);

        position: relative;

        user-select: none;

        @{desktop} & {
            .swiper-wrapper {
                padding-top: rem(5);
                padding-bottom: rem(5);
            }

            .swiper-container {
                padding-right: rem(3);
                padding-left: rem(3);
            }
        }


        &__slider {
            margin-bottom: rem(18);
        }

        &__slide {
            width: rem(150);
        }

        .swiper {
            &-slide {
                padding: rem(2);
                width: rem(156);
            }
        }

        &__swiper-slider {
            opacity: 0;

            transition: opacity .15s ease;

            &_loaded {
                opacity: 1;
            }
        }

        @{desktop} &__button {
            display: flex;
            align-items: center;
            justify-content: center;

            background: transparent;
            border: none;
            border-radius: rem(3);

            padding: 0;

            height: rem(80);
            width: rem(24);

            top: 31%;
            z-index: 2;

            transition: background-color .075s ease;

            user-select: none;

            svg {
                color: @color-black;

                height: rem(18);
                width: rem(18);
            }

            &_prev {
                left: rem(-40);

                
                @media (max-width: 1140px){
                    left: rem(-20);
                }
            }

            &_next {
                right: rem(-38);

                @media (max-width: 1140px){
                    right: rem(-20);
                }
            }

            &:after,
            &:before {
                display: none;
            }

            .hover({
                background-color: @color-bg-gray;
            });
        }
    }

    &-row_recent{
        padding: 0;

        @{desktop} & {
            .swiper-wrapper{
                padding-top: 0;
                padding-bottom: 0;
            }

            .block-persons-row{
                &__button{
                    top: 0;
                    height: rem(75);
                }
            }
        }

        @{mobile} & {
            position: relative;

            &::before,
            &::after{
                position: absolute;
                top: 0;
                bottom: 0;
                width: rem(31);
                content: '';
                z-index: 2;
                pointer-events: none;
            }

            &::before{
                left: 0;
                background: linear-gradient(270deg, rgba(255,255,255,0) 0%, white 100%);
            }

            &::after{
                right: 0;
                background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
            }

            .block-persons-row{
                &__slide{
                    width: auto;
                    padding-right: rem(16);
                }

                &__slider{
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
