<i18n>
    {
    "ru": {
        "show_replies_start": "Показать",
        "replies_one": "ответ",
        "replies_few": "ответа",
        "replies_many": "ответов",
        "hide_replies_start": "Скрыть",
        "guest": "Гость",
        "reply": "Ответить",
        "follow": "Перейти",
        "show_user_comments": "Все отзывы пользователя",
        "hide_user_comments": "Скрыть отзывы пользователя",
        "at": "в",
        "he_left_a_review": "оставил отзыв",
        "she_left_a_review": "оставила отзыв",
        "is_brand_review": "о бренде",
        "go_to_post": "Перейти"
    }
}
</i18n>

<template>
    <section
        class="feed-post"
        :id="blockId">

        <BlockFeedPostHeader
            :no-gender="noGender"
            :name="name"
            :gender="gender"
            :photo="photo"
            :html-url="htmlUrl"
            :perfumeName="perfumeName"
            :created-by="postData.createdBy"
            :post-type="postType"
            :rating="rating"
            :post-id="postData.id"/>

        <template v-if="showLess">
            <!--eslint-disable-next-line-->
            <div v-html="$options.filters.textNoBr(text)" class="feed-post__content feed-post__content_less"></div>
            <div class="feed-post__date">{{ postDate }}</div>
        </template>

        <template v-else>
            <ShowMore
                class="feed-post__content"
                :expanded="expanded"
                :max-rows="maxRows"
                :collapse-from="scrollToBlock"
                :collapse-to="scrollToBlock">
                <!--eslint-disable-next-line-->
            <div v-html="text"></div>
            </ShowMore>

            <div
                class="block-review__pictures"
                v-if="pictures.length">
                <picture
                    v-for="(picture, index) in pictures"
                    :key="picture.url"
                    @click="showGallery(index)">

                    <img
                        v-lazy-load :data-src="picture.url"
                        :alt="picture.alt || ''" />

                </picture>
            </div>

            <div class="feed-post__buttons">
                <RateButtons
                    class="feed-post__rate-buttons"
                    :comment-id="postData.id"
                    :likes="likes"
                    :dislikes="dislikes" />

                <button class="feed-post__reply-to"
                    v-if="canAnswer && !countReplies"
                    @click.prevent="replyTo">{{ $t('reply') }}</button>

                <nuxt-link
                    class="feed-post__go-to"
                    v-if="canAnswer && !countReplies"
                    to="/">{{ $t('go_to_post' )}}</nuxt-link>
            </div>

            <TransitionExpand>
                <div class="block-review__replies"
                    v-show="isShowReplies">

                    <BlockReview
                        class="block-review__reply"
                        :idPrefix="idPrefix"
                        v-for="reply in replies"
                        :key="reply.id"
                        :can-answer="false"
                        :comment="reply" />
                </div>
            </TransitionExpand>

            <footer class="feed-post__footer"
                v-if="countReplies">

                <transition
                    mode="out-in"
                    name="pop">

                    <button class="feed-post__button-show-replies"
                        v-if="countReplies"
                        :key="buttonShowText"
                        @click.prevent="triggerShowReplies">
                        {{ buttonShowText }}
                    </button>

                </transition>

                <button class="feed-post__reply-to"
                    v-if="canAnswer"
                    @click.prevent="replyTo">{{ $t('reply') }}</button>

                <nuxt-link
                    class="feed-post__go-to"
                    v-if="canAnswer"
                    to="/">{{ $t('go_to_post' )}}</nuxt-link>
            </footer>
        </template>

    </section>
</template>

<script>
// import { mapGetters } from 'vuex'
import get from 'lodash/get'
import orderBy from "lodash/orderBy";
import throttle from "lodash/throttle";
import dayjs from 'dayjs'
import RateButtons from '@/components/ui/RateButtons'
import BlockReview from '@/components/layout/blocks/BlockReview'
import BlockFeedPostHeader from '@/components/feed/blocks/BlockFeedPostHeader'

export default {
    name: 'BlockFeedPerfume',
    components: {
        RateButtons,
        BlockFeedPostHeader,
        BlockReview
    },
    props: {
        noGender: {
            type: Boolean,
            default: false
        },
        showLess: {
            type: Boolean,
            default: false
        },
        postType: {
            type: String,
            default: null
        },
        postData: {
            type: Object,
            default: () => {}
        },
        expanded: {
            type: Boolean,
            default: false
        },
        maxRows: {
            type: Number,
            default: 4
        },
        canAnswer: {
            type: Boolean,
            default: true
        },
        idPrefix: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        rowsCount: 0,
        isShowReplies: false,
        replies: []
    }),
    computed: {
        isShowed() {
            return this.postData.text && this.postData.stars > 0
        },
        name() {
            return get(this.postData, 'createdBy.name', this.$t('guest'))
        },
        gender() {
            return get(this.postData, 'createdBy.gender')
        },
        perfumeName() {
            return get(this.postData, 'perfume.brand.name') + ' ' + get(this.postData, 'perfume.name')
        },
        htmlUrl() {
            return get(this.postData, 'perfume.htmlUrl')
        },
        photo() {
            return get(this.postData, 'perfume.picture')
        },
        rating() {
            return get(this.postData, 'stars')
        },
        text() {
            return get(this.postData, 'text')
        },
        likes() {
            return get(this.postData, 'ratings.likes', 0)
        },
        dislikes() {
            return get(this.postData, 'ratings.dislikes', 0)
        },
        pictures() {
            return get(this.postData, 'pictures', [])
        },
        blockId() {
            const date = new Date(get(this.postData, 'createdAt', ''))

            const dateStr = dayjs(date).format('DDMMYYYYhmm')

            return this.idPrefix + '_' + (this.postData.createdBy.name || this.postData.createdBy.id).replace(/\s+/g, '') + dateStr
        },
        scrollToBlock() {
            return '#' + this.blockId
        },
        scrollToReplies() {
            return '#' + this.repliesId
        },
        repliesId() {
            return this.idPrefix + '_' + 'replies' + this.blockId
        },
        repliesDeclension() {
            return [
                this.$t('replies_one'),
                this.$t('replies_few'),
                this.$t('replies_many')
            ]
        },
        buttonShowText() {
            const end = this.$options.filters.numberDeclension(this.countReplies, this.repliesDeclension)

            const startKey = this.isShowReplies ? 'hide_replies_start' : 'show_replies_start'
            const startStr = this.$t(startKey)

            return `${startStr} ${end}`
        },
        getReplies() {
            const replies = get(this.postData, 'replies', [])

            return orderBy(replies, reply => {
                return new Date(reply.createdAt)
            }, 'asc')
        },
        countReplies() {
            return this.replies.length
        },
        replyToEventName() {
            return 'reply-to-comment-' + this.blockId
        },
        postDate(){
            const translations = {
                lastday_in: this.$t('date.lastday_in'),
                today_in: this.$t('date.today_in'),
                in: this.$t('date.in'),
            }

            return this.$options.filters.datePost(this.postData.createdAt, translations)
        }
    },
    beforeDestroy() {
        if (this.$bus._events[this.replyToEventName])
            this.$bus._events[this.replyToEventName] = []

        if (this.$bus._events[this.commentToEventName])
            this.$bus._events[this.commentToEventName] = []
    },
    mounted() {
        this.replies = this.getReplies

        // eslint-disable-next-line
        if (process.client) {
            this.$bus.$on(this.replyToEventName, callback => {
                callback()
            })

            this.$bus.$on(this.commentToEventName, comment => {
                this.replies.push(comment)
                this.replies = this.getReplies
            })
        }
    },
    methods: {
        triggerShowReplies: throttle(function() {
            this.isShowReplies = !this.isShowReplies

            if (this.isShowReplies) {
                this.$scrollTo(this.scrollToReplies, 750, {
                    offset: -56
                })
            } else {
                this.$scrollTo(this.scrollToBlock, 750, {
                    offset: -56
                })
            }
        }),
        showGallery(selectedSlide) {
            this.showPopup('comment-gallery', {
                pictures: this.pictures,
                name: this.name,
                photo: this.photo,
                selectedSlide
            })
        },
        replyTo() {
            this.$authEmit(this.replyToEventName, () => {
                this.showPopup('popup-comment', {
                    replyTo: this.postData.id
                })
            })
        }
    }
}
</script>

<style scoped lang="less">
.feed-post {
    --photo-width: rem(40);
    --photo-height: rem(40);
}
</style>
