<template>
    <div class="page-slider">

        <swiper
            class="page-slider__swiper"
            ref="swiper"
            @slide-change="onSlideChange"
            :options="swiperOptions">

            <swiper-slide
                v-for="(section, index) in sections"
                :key="index + 'asdsadsadasdas'">

                <slot
                    name="item"
                    :section="section">
                </slot>

            </swiper-slide>
        </swiper>

        <div class="page-slider__controls">
            <button
                v-if="sections && sections.length > 1"
                :id="`${_uid}_page_slider_prev`"
                class="swiper-button-prev page-slider__button page-slider__button_prev">
                <svg-icon name="chevron-left" />
            </button>

            <div
                v-if="sections && sections.length > 1"
                :id="`${_uid}_page_slider_dots`"
                class="page-slider__dots swiper__dots">
            </div>

            <button
                v-if="sections && sections.length > 1"
                :id="`${_uid}_page_slider_next`"
                class="swiper-button-next page-slider__button page-slider__button_next">
                <svg-icon name="chevron-right" />
            </button>
        </div>

    </div>
</template>

<script>
import get from 'lodash/get'

export default {
    name: 'PageSlider',
    props: {
        sections: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 1,
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                pagination: {
                    el: `#${this._uid}_page_slider_dots`,
                    clickable: true
                },
                navigation: {
                    prevEl: `#${this._uid}_page_slider_prev`,
                    nextEl: `#${this._uid}_page_slider_next`
                },
                autoplay: false,
                simulateTouch: false,
                autoHeight: true
            }
        }
    },
    mounted() {

        // eslint-disable-next-line
        if (process.client) {
            setTimeout(() => {
                this.$nextTick(() => {
                    if (this.$refs.swiper) {
                        this.lazyLoadImage(this.$refs.swiper.$el)
                        this.$refs.swiper.updateSwiper()
                    }
                })
            }, 0)
        }
    },
    methods: {
        get,
        onSlideChange() {
            this.$nextTick(() => {
                this.lazyLoadImage(this.$refs.swiper.$el)
            })
        },
        lazyLoadImage(e) {
            const media = e.querySelectorAll('img[data-src]');
            [...media].forEach(m => this.$lazyLoad(m))
        },
    }
}
</script>

<style lang="less">
.page-slider {
    display: flex;
    flex-direction: column;
    height: inherit;

    &__swiper {
        // padding-bottom: rem(11);
        width: 100%;

        .swiper-slide{
            background-color: white;

            &-active{
                z-index: 1;
            }
        }

        &.swiper-container-initialized{
            &+.page-slider__controls{
                .swiper-button-prev,
                .swiper-button-next{
                    display: block;
                }
            }
        }
    }

    &__button {
        background: @color-gray-light-2;
        border: none;
        border-radius: 50%;
        color: @color-black;

        padding: 0;

        height: rem(36);
        width: rem(36);

        top: auto;
        // bottom: rem(3.5);

        transition: opacity .2s ease, background-color .15s ease;
        position: relative;

        .hover({
            background-color: @color-gray-white;
        });

        @{desktop} & {
            height: rem(26);
            width: rem(26);

            // bottom: rem(15);
        }

        svg {
            height: rem(16);
            width: rem(16);
        }

        // &_prev {
        //     left: @dim-page-padding;

        //     @{desktop} & {
        //         lefT: rem(1);
        //     }
        // }

        // &_next {
        //     right: @dim-page-padding;

        //     @{desktop} & {
        //         right: rem(1);
        //     }
        // }

        &:after,
        &:before {
            display: none;
        }
    }

    &__dots {
        // padding-top: rem(17);
        // padding-bottom: rem(6);

        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .swiper-pagination-bullet {
            background-color: @color-gray-2;
            margin: 0 rem(6);
            width: rem(6);
            height: rem(6);
            opacity: 1;

            .hover({
                background-color: @color-gray-light;
            });

            &-active {
                background-color: @color-black;
            }
        }


        @{mobile} & {
            position: relative;
            // bottom: rem(-4);
        }
    }

    &__controls{
        position: relative;
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(20) 0;
        border-top: rem(1) solid @color-gray-white;

        @{mobile} & {
            padding: rem(14) rem(20);
        }
    }
}

</style>

