<template>
    <html-link
        :url="offerData.htmlUrl"
        class="block-offer-picture"
        :class="computedClass">
        <img v-lazy-load :data-src="picture"
            :alt="offerData.name"
            width="71"
            height="71"
            v-tooltip="computedTooltip" />
    </html-link>
</template>

<script>
import get from 'lodash/get'
import { mapGetters } from 'vuex'

export default {
    name: 'BlockOfferPictureOnly',
    props: {
        offerData: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: 'perfume'
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop', 'getEntityNoImage']),
        picture(){
            let picture = '';

            switch(this.type){
            case 'brand':
                picture = get(this.offerData, 'logo.url', this.getEntityNoImage('brand_alt'));
                break;
            case 'perfumer':
                picture = get(this.offerData, 'picture.url', this.getEntityNoImage('nose_alt'));
                break;
            case 'note':
            case 'noteGroup':
                picture = get(this.offerData, 'picture.url', this.getEntityNoImage('note_alt'));
                break;
            default:
                picture =  get(this.offerData, 'picture.url', this.getEntityNoImage('perfume'));
                break;
            }

            return picture;
        },
        isWithPicture(){
            let isWithPicture = true;

            switch(this.type){
            case 'brand':
                isWithPicture = get(this.offerData, 'logo.url', false);
                break;
            default:
                isWithPicture = get(this.offerData, 'picture.url', false);
                break;
            }

            return isWithPicture;
        },
        computedTooltip(){
            const text = this.type === 'perfumes' ? `${this.offerData.brand.name} ${this.offerData.name}` : this.offerData.name;
            return this.tooltipOptions(text);
        },
        computedClass(){
            return {
                'block-offer-picture_no-picture': !this.isWithPicture,
                'block-offer-picture_rounded': (this.type === 'perfumer' ||  this.type === 'note' || this.type === 'noteGroup') && this.isWithPicture,
            }
        }
    },
    methods: {
        tooltipOptions(name){
            const desktopOptions = {
                content: name,
                delay: { show: 50, hide: 50 },
                offset: 8,
                defaultPlacement: 'bottom',
                boundariesElement: 'body'
            }
            return this.isDeviceDesktop ? desktopOptions : {}
        }
    }
}
</script>
<style lang="less">
.block-offer-picture{
    width: rem(71);
    height: rem(71);
    display: flex;
    align-items: center;
    justify-content: center;

    &>img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @{mobile} & {
        width: rem(64);
        height: rem(64);
    }

    &_no-picture{
        &>img{
            max-width: rem(62);
            max-height: rem(62);

            @{mobile} & {
                max-width: rem(56);
                max-height: rem(56);
            }
        }
    }

    &_rounded{
        overflow: hidden;
        border-radius: 100%;
        transform: translate3d(0, 0, 0);

        &>img{
            object-fit: cover;
        }
    }
}
</style>