<template>
    <div class="block-person-card"
        :class="rootClasses">
        <div class="block-person-card__photo-wrapper">
            <img class="block-person-card__photo"
                v-lazy-load :data-src="getPicture"
                :alt="isBrand ? userData.name : ''"/>
        </div>
        <div class="block-person-card__title">

            <!-- eslint-disable-next-line -->
            <html-link v-html="title"
                v-if="userData && userData.htmlUrl && !disabledLink"
                class="block-person-card__link"
                :data-fullname="!isFullName"
                :url="userData.htmlUrl">
            </html-link>

            <span v-else> {{ userData.name }} </span>
        </div>
        <div class="block-person-card__caption" v-if="caption">
            {{ caption }}
        </div>
        <div class="block-person-card__caption" v-if="userData.caption">
            {{ userData.caption }}
        </div>
        <div class="block-person-card__liked" v-if="userData.statusPerfumeLikes && !withoutLikes">
            <svg-icon
                name="icon-heart"
                class="liked__icon" />
            <span>{{ userData.statusPerfumeLikes.total }}</span>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
import get from 'lodash/get'
import { mapGetters } from "vuex";
import HtmlLink from '@/components/ui/BaseHtmlLink';

export default {
    name: 'BlockPersonCard',
    components: {HtmlLink},
    props: {
        disabledLink: {
            type: Boolean,
            default: () => false
        },
        isFullName: {
            type: Boolean,
            default: true
        },
        withoutLikes: {
            type: Boolean,
            default: true
        },
        userData: {
            type: Object,
            default: () => {}
        },
        caption: {
            type: String,
            default: null
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        actionButtons: {
            type: Boolean,
            default: () => false
        },
        isBrand: {
            type: Boolean,
            default: false
        },
        isNote: {
            type: Boolean,
            default: false
        },
        isPerson: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['getUserNoImageLarge', 'getEntityNoImage']),
        getPicture() {
            if(this.isBrand)
                return get(this.userData, 'logo.url', this.getEntityNoImage('brand'))

            return get(this.userData, 'photo.url', get(this.userData, 'picture.url', this.getUserNoImageLarge))
        },
        rootClasses() {
            return {
                'block-person-card_captioned': get(this.userData, 'caption'),
                'block-person-card_noborder': this.noBorder,
                'block-person-card_brand': this.isBrand
            }
        },
        title(){
            if(this.isBrand || this.isNote || this.isPerson)
                return this.userData.name

            if(this.isFullName)
                return this.userData.fullName

            let title = this.userData.name
            if(this.userData.lastName) title += `<br />${this.userData.lastName }`

            return title
        }
    },
    methods: {
        get
    }
};
</script>

<style lang="less">

.block-person-card {
    flex-direction: column;

    border: rem(1) solid @color-gray-white;
    border-radius: rem(3);
    font-size: rem(13);
    font-weight: 500;
    text-align: center;

    padding: rem(7.5);
    padding-bottom: rem(17);

    min-height: rem(156);

    position: relative;

    @{desktop} & {
        padding-bottom: rem(18);
    }

    &_noborder{
        border: none;
        transition: box-shadow .15s ease;

        &:hover,
        &:focus {
            @media @hover {
                box-shadow: 0 rem(1) rem(8) rgba(0, 0, 0, 0.1);
            }
        }

        .block-person-card{
            &__photo-wrapper{
                height: rem(72);
                width: rem(72);

                @{desktop} & {
                    height: rem(104);
                    width: rem(104);
                }
            }

            // &__link{
            //     &::before{
            //         height: rem(150);
            //     }
            // }
        }
    }

    &__photo-wrapper {
        width: rem(88);
        height: rem(88);
        margin: rem(7) auto rem(13);

        @{desktop} & {
            margin-bottom: rem(20);
            width: rem(90);
            height: rem(90);
        }
    }

    &__photo {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
        border-radius: 100%;
        transform: scale(1.000001);
    }

    &__caption {
        color: @color-gray-light;
        font-size: rem(13);
        font-weight: 400;
    }

    &__title {
        color: @color-black;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: rem(2);
        overflow: hidden;

        [data-fullname] {
            display: block;

            white-space: initial;
            text-overflow: ellipsis;
            overflow: hidden;

            // padding: 0 @dim-page-padding;

            min-height: rem(39);
        }
    }

    &__link {
        color: @color-black;
        white-space: nowrap;
        transition: color .15s ease;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // top: rem(5);
            // right: rem(10);
            // left: rem(10);
            // height: rem(128);
        }

        .hover({
            color: @color-primary;
        });
    }

    &__liked {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: rem(8) 0 0;

        opacity: 0.3;
    }

    &_captioned {
        height: rem(194);
    }

    &_captioned &__link {
        display: block;

        white-space: normal;

        padding: 0 rem(20);
    }

    &_main{
        @{desktop} & {
            padding-top: rem(20);

            .block-person-card{
                &__title{
                    font-size: rem(15);
                    line-height: rem(23);
                }

                &__photo-wrapper{
                    margin-bottom: rem(16);
                    margin-top: 0;
                }
            }
        }
    }

    &_brand{
        .block-person-card{
            &__photo{
                object-fit: contain;
                border-radius: 0;
            }
        }
    }
}

.liked {
    &__icon {
        --size: rem(15);
        margin-top: rem(-2);
        margin-right: rem(4.5);

        height: var(--size);
        width: var(--size);
    }
}
</style>
